import React, { useEffect, useState } from 'react';
import { genericRequest } from '../../utils/request';
import { Column, Container, Row } from '../../components/Layout';
import Loader from "../../components/Loading";
import { spacing } from '../../constants/styles.constants';
import { useGlobalState } from '../../hooks/useGlobalState';
import LogoHeading from "../../components/LogoHeading";
import style from "./LandingPage.module.scss"
import { Link, Button } from 'carbon-components-react';
import EditIcon from '@carbon/icons-react/lib/arrow--right/16';
import { useHistory } from "react-router-dom";

const LandingPage = () => {
    const history = useHistory();
    const [{ user }, { setUser }] = useGlobalState();
    const [requestStatus, setRequestStatus] = useState({
        isError: false,
        isLoading: false,
        isSuccess: false
    });

    useEffect(() => {
        async function getPageData() {
            setRequestStatus({ isLoading: true });
            const request = await genericRequest({ endpoint: 'getUserDetails' });

            if (request.response) {
                setUser({ data: request.response.data });
                return setRequestStatus({ isSuccess: true });
            }

            return setRequestStatus({ isError: request.error });
        }

        getPageData().then()
    }, [setRequestStatus, setUser]);

    return (
        <Container className={style.centreAlign}>
            <LogoHeading/>
            <div className={style.imageContainer}>
                <img className={style.logo} alt={"Logo"} src={"/images/logo.png"}/>
            </div>
            <Loader isLoading={requestStatus.isLoading}
                    isError={requestStatus.isError}
                    isSuccess={requestStatus.isSuccess}
                    errorMessage={"We Couldn't Get Your Details"}
                    successMessage={"We got your details"}
            >
                <Row space={spacing.medium}>
                    <Column>
                        <h2>Hello {user.firstName} {user.lastName}</h2>
                    </Column>
                </Row>
            </Loader>
            <Row space={spacing.medium}>
                <Column>
                    <p>
                        Welcome to Yesterday Stories Admin. From this portal you can add stories and advertisements to
                        be shown in the Yesterday Stories App.
                    </p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <p>Get Started by Creating a new Story or Advertisement</p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Button onClick={() => history.push("/advertisements/create")}
                            className={style.largeButton} kind="primary">Create an
                        Advertisement <EditIcon className={style.iconGap}/></Button>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Button onClick={() => history.push("/stories/create")}
                            className={`${style.largeButton}`}
                            kind="primary">Create a Story <EditIcon
                        className={style.iconGap}/></Button>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <h3>Advertisements</h3>
                    <p>You can see all your advertisement <Link href="/advertisements/list">here</Link></p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <h3>Stories</h3>
                    <p>You can see all your stories <Link href="/stories/list">here</Link></p>
                </Column>
            </Row>
            {user.isAdmin && (
                <Row space={spacing.medium}>
                    <Column>
                        <h3>Users</h3>
                        <p>Admin can see all users <Link href="/users/list">here</Link></p>
                    </Column>
                </Row>
            )}
        </Container>
    );
};

export default LandingPage;
