import React, { useEffect, useState } from 'react';
import { genericRequest } from '../../utils/request';
import { Column, Container, Row } from '../../components/Layout';
import Loader from "../../components/Loading";
import { spacing } from '../../constants/styles.constants';
import { useGlobalState } from '../../hooks/useGlobalState';
import LogoHeading from "../../components/LogoHeading";
import style from "./Contact.module.scss"
import { Button, Link, Tag } from 'carbon-components-react';
import LogoutIcon from "@carbon/icons-react/lib/logout/16";

const LandingPage = () => {
    const [{ user }, { setUser }] = useGlobalState();
    const [requestStatus, setRequestStatus] = useState({
        isError: false,
        isLoading: false,
        isSuccess: false
    });

    const logout = () => {
        window.localStorage.removeItem('auth');
        window.location.reload();
        return false;
    };

    useEffect(() => {
        async function getPageData() {
            setRequestStatus({ isLoading: true });
            const request = await genericRequest({ endpoint: 'getUserDetails' });

            if (request.response) {
                setUser({ data: request.response.data });
                return setRequestStatus({ isSuccess: true });
            }

            return setRequestStatus({ isError: request.error });
        }

        getPageData().then()
    }, [setRequestStatus, setUser]);

    return (
        <Container className={style.centreAlign}>
            <LogoHeading/>
            <div className={style.imageContainer}>
                <img className={style.logo} alt={"Logo"} src={"/images/logo.png"}/>
            </div>
            <Loader isLoading={requestStatus.isLoading}
                    isError={requestStatus.isError}
                    isSuccess={requestStatus.isSuccess}
                    errorMessage={"We Couldn't Get Your Details"}
                    successMessage={"We got your details"}
            >
                <Row space={spacing.medium}>
                    <Column>
                        <h2>Yesterday Stories Support Page</h2>
                    </Column>
                </Row>
            </Loader>
            <Row space={spacing.medium}>
                <Column>
                    <p>
                        Please reach out to us at <Link
                        target="_blank"
                        href={`mailto:support@yesterdaystories.com.au?subject=Yesterday Stories Support&body=Please include User ID ${user.cognitoUserId} and Payment ID ${user.stripeId} in your email thank you.`}>
                        support@yesterdaystories.com.au
                    </Link> and we will get back to you as soon as we can.
                    </p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <p>Your unique id is <Tag>{user.cognitoUserId}</Tag> and your payment id
                        is <Tag>{user.stripeId}</Tag>.
                        Please quote these
                        id's when messaging support.</p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Button onClick={logout}
                            className={`${style.largeButton}`}
                            kind="primary">Logout <LogoutIcon
                        className={style.iconGap}/></Button>
                </Column>
            </Row>
        </Container>
    );
};

export default LandingPage;
