import React, { useState, useEffect } from 'react';
import {
    DataTable,
    TableContainer,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Toggle,
    TableToolbar,
    TableToolbarContent,
    Button,
} from 'carbon-components-react';
import { useHistory, withRouter } from "react-router-dom";
import EditIcon from '@carbon/icons-react/lib/edit/16';
import BinIcon from '@carbon/icons-react/lib/trash-can/16';
import moment from 'moment'
import style from './AdvertisementsList.module.scss'
import endpoints from '../constants/endpoints.constants'
import { genericTrailsRequest } from "../utils/request";
import { compareAlphabetically, compareDates } from "../utils/sort";
import Loader from "./Loading";

export default withRouter((props) => {
    const history = useHistory();
    const [requestStatusEdit, setRequestStatusEdit] = useState([]);
    const [requestStatusDelete, setRequestStatusDelete] = useState({
        isError: false,
        isSuccess: false,
        isLoading: false,
    });

    const toggleApproval = async ({ approvalStatus, approvalListId, trailId, event }) => {
        requestStatusEdit[approvalListId] = { isLoading: true }

        setRequestStatusEdit(previousState => {
            previousState[approvalListId] = { isLoading: true }
            return [...previousState]
        })

        const request = await genericTrailsRequest({
            endpoint: 'trails/enable',
            method: "POST",
            data: {
                id: `${trailId}`,
                enabled: approvalStatus,
            },
        });

        if (request.error) {
            event.target.checked = !approvalStatus
        }

        return setRequestStatusEdit(previousState => {
            previousState[approvalListId] = { isLoading: false }
            return [...previousState]
        })
    };

    const deleteTrail = async ({ trailId }) => {
        if (!window.confirm("Do you really want to delete?")) {
            return false
        }

        setRequestStatusDelete({ isLoading: true });

        const request = await genericTrailsRequest({
            endpoint: 'trails',
            method: "DELETE",
            data: {
                id: trailId
            },
        });

        if (request.error) {
            return setRequestStatusDelete({ isError: true, isLoading: false });
        }

        props.trailList.splice(props.trailList.findIndex(trail => trail.id === trailId), 1)
        return setRequestStatusDelete({ isSuccess: true, isLoading: false });
    };

    useEffect(() => {
        const requestStatusBuilder = [];
        props.trailList.forEach(() => {
            requestStatusBuilder.push({ isLoading: false })
        })

        setRequestStatusEdit(requestStatusBuilder)
    }, [props.trailList, history])

    const headerData = [
        {
            header: "Name",
            key: "trail_name",
        },
        {
            header: "Modified",
            key: "modified_date",
        },
        {
            header: "Trail Description",
            key: "trail_description",
        },
        {
            header: "Edit",
            key: "edit",
        },
        {
            header: "Delete",
            key: "deleteTrail",
        },
    ];

    if (props.userData.isAdmin) {
        headerData.push(
            {
                header: "Approved",
                key: "approval",
            },
        )
    }

    const compare = (cellA, cellB, rowId) => {
        if (rowId === "modified_date") {
            return compareDates(cellA, cellB);
        }

        return compareAlphabetically(cellA, cellB);
    }

    return (
        <>
            <Loader isLoading={requestStatusDelete.isLoading}
                    isError={requestStatusDelete.isError}
                    isSuccess={requestStatusDelete.isSuccess}
                    errorMessage={"We Couldn't Delete the Trail"}
                    successMessage={"Deleted Trail"}
            />
            <DataTable isSortable
                       headers={headerData}
                       sortRow={(cellA, cellB, data) => {
                           if (data.sortDirection === data.sortStates.DESC) {
                               return compare(cellB, cellA, data.key);
                           }

                           return compare(cellA, cellB, data.key);
                       }}
                       rows={
                           props.trailList.map((trail, key) => {
                                   return (
                                       {
                                           id: trail.id.toString(),
                                           trail_name: trail.title,
                                           modified_date: `${moment(new Date(trail.updated_at)).format('MMMM Do YYYY, h:mm:ss a')}`,
                                           trail_description: trail.description,
                                           approval: (
                                               <div>
                                                   <Toggle
                                                       size="sm"
                                                       aria-label="Approval Toggle"
                                                       defaultToggled={trail.is_approved}
                                                       id={`approval-toggle-${trail.id}`}
                                                       labelText={''}
                                                       disabled={requestStatusEdit[key] ? requestStatusEdit[key].isLoading : false}
                                                       onClick={async (event) => {
                                                           event.persist()
                                                           await toggleApproval({
                                                               trailId: trail.id,
                                                               approvalListId: key,
                                                               approvalStatus: event.target.checked,
                                                               event,
                                                               isAds: false,
                                                           })
                                                       }}
                                                   />
                                               </div>
                                           ),
                                           edit: (
                                               <button
                                                   className={style.styleLessButton}
                                                   rel="noopener noreferrer"
                                                   onClick={() => history.push(endpoints.trailDetails({ trailId: trail.id }))}
                                               >
                                                   <EditIcon/>
                                               </button>
                                           ),
                                           deleteTrail: (
                                               <button
                                                   className={style.styleLessButton}
                                                   rel="noopener noreferrer"
                                                   onClick={() => deleteTrail({ trailId: trail.id })}
                                               >
                                                   <BinIcon/>
                                               </button>
                                           ),
                                       }
                                   )
                               }
                           )
                       }
                       render={({ rows, headers, getHeaderProps }) => (
                           <TableContainer
                               title="Trail List"
                               description={
                                   <span><br/>Your Trails Will Be Shown Here</span>
                               }>
                               <TableToolbar>
                                   <TableToolbarContent>
                                       <Button
                                           tabIndex={1}
                                           onClick={() => history.push(endpoints.createTrail())}
                                           size="small"
                                           kind="primary">
                                           Create Trail
                                       </Button>
                                       <TableToolbarSearch onChange={props.trailListFilter}/>
                                   </TableToolbarContent>
                               </TableToolbar>
                               <Table useZebraStyles size='normal'>
                                   <TableHead>
                                       <TableRow>
                                           {headers.map(header => (
                                               <TableHeader {...getHeaderProps({ header })}>
                                                   {header.header}
                                               </TableHeader>
                                           ))}
                                       </TableRow>
                                   </TableHead>
                                   <TableBody>
                                       {rows.map(row => (
                                           <TableRow key={row.id}>
                                               {row.cells.map(cell => (
                                                   <TableCell className={
                                                       cell.id.includes("approval") ||
                                                       cell.id.includes("deleteTrail") ||
                                                       cell.id.includes("edit")
                                                           ? style.centreAlign : ''
                                                   } key={cell.id}>{cell.value}</TableCell>
                                               ))}
                                           </TableRow>
                                       ))}
                                   </TableBody>
                               </Table>
                           </TableContainer>
                       )}
            />
        </>
    );
});
