import React, { useState } from 'react';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import keys from '../constants/keys.constants'
import { Button } from "carbon-components-react";
import style from './PurchaseDetails.module.scss'
import { spacing } from "../constants/styles.constants";
import { Column, Row } from "./Layout";
import Loading from "./Loading";
import { genericRequest } from "../utils/request";

const stripePromise = loadStripe(keys.stripeApiKey);

const CheckoutForm = (props) => {
    const { stripe, elements } = props;
    const [requestPaymentSave, setRequestPaymentSave] = useState({
        isError: false,
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    });

    const saveCardDetails = async (event) => {
        event.preventDefault();

        setRequestPaymentSave({
            isError: false, isLoading: true, isSuccess: false,
        })

        const cardElement = elements.getElement(CardElement);
        const createdPayment = await stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

        if (createdPayment.error) {
            return setRequestPaymentSave({
                isError: true, isLoading: false, isSuccess: false, errorMessage: createdPayment.error.message,
            })
        }

        const savePayment = await genericRequest({
            endpoint: 'updateUserPayment',
            method: 'POST',
            data: {
                stripePaymentId: createdPayment.paymentMethod.id
            }
        })

        if (savePayment.error) {
            return setRequestPaymentSave({
                isError: true,
                isLoading: false,
                isSuccess: false,
                errorMessage: "We Could Not Save your Card with Stripe, Please Try Again"
            })
        }

        setRequestPaymentSave({
            isError: false, isLoading: false, isSuccess: true,
        })
    }

    return (
        <>
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isError={requestPaymentSave.isError}
                        isLoading={requestPaymentSave.isLoading}
                        isPermanentSuccess={requestPaymentSave.isSuccess}
                        errorMessage={requestPaymentSave.errorMessage}
                        successMessage="Successfully Saved Your Card with Stripe"
                    />
                </Column>
            </Row>
            <form onSubmit={saveCardDetails}>
                <CardElement
                    className={style.cardElement}
                    options={{
                        disabled: requestPaymentSave.isSuccess || requestPaymentSave.isLoading,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <Button
                    type={"submit"}
                    kind="primary"
                    tabIndex={0}
                    disabled={requestPaymentSave.isSuccess}
                >
                    Save Card
                </Button>
            </form>
        </>
    )
};

const InjectedCheckoutForm = () => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <CheckoutForm elements={elements} stripe={stripe}/>
            )}
        </ElementsConsumer>
    );
};

// eslint-disable-next-line
export default () => (
    <>
        <span>Please enter your card details, be sure to save them before paying</span>
        <Elements stripe={stripePromise}>
            <InjectedCheckoutForm/>
        </Elements>
    </>
)
