import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Layout';
import UserDetails from "../../components/UserDetails";
import { genericRequest } from "../../utils/request";
import LogoHeading from "../../components/LogoHeading";

const AuthCallback = () => {
    const [needsDetails, setNeedsDetails] = useState(false)
    const [status, setStatus] = useState({
        isError: false,
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
    });

    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect');

    useEffect(() => {
        async function getPageData() {
            const request = await genericRequest({ endpoint: 'getUserDetails' });

            if (request.error || (request.response.data.firstName !== "Yesterday Stories" && request.response.data.lastName !== "User")) {
                return window.location.replace(redirectUrl);
            }

            setNeedsDetails(true);
        }

        getPageData().then()
    }, [redirectUrl]);

    const submitUserDetails = async ({ firstName, lastName }) => {
        setStatus({ isLoading: true });

        const request = await genericRequest({
            endpoint: 'setUserDetails',
            method: "POST",
            data: {
                firstName,
                lastName
            }
        });

        if (request.error) {
            return setStatus({ isLoading: false, isError: true, errorMessage: request.error.toString() });
        }

        setStatus({ isSuccess: true });
        setTimeout(() => {
            return window.location.replace(redirectUrl)
        }, 3000);
    };

    return (
        <Container>
            <LogoHeading/>
            {needsDetails ? <div>
                <h2>Please Update Your Details</h2>
                <UserDetails
                    isLoading={status.isLoading}
                    isError={status.isError}
                    isSuccess={status.isSuccess}
                    errorMessage={status.errorMessage}
                    successMessage={"We Saved Your Details"}
                    submit={submitUserDetails}
                />
            </div> : <h1>Please Wait</h1>}
        </Container>
    );
};

export default AuthCallback;
