import React from 'react';
import style from "./Footer.module.scss"

const footer = () => (
    <footer className={style.centreAlign}>
        &copy; 2021 Yesterday Stories
    </footer>
);

export default footer
