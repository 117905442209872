import React from 'react';
import {
    Header,
    HeaderName,
    HeaderNavigation,
    HeaderMenuItem,
    SkipToContent,
} from 'carbon-components-react/es/components/UIShell';
import style from './Header.module.scss';

// eslint-disable-next-line
export default () => (
    <Header className={style.header} aria-label="Yesterday Stories">
        <SkipToContent/>
        <HeaderName className={style.headerText} href="/" prefix={""}>
            Yesterday Stories
        </HeaderName>
        <HeaderNavigation className={style.overRide} aria-label="Yesterday Stories">
            <HeaderMenuItem href="/advertisements/list">Advertisements</HeaderMenuItem>
            <HeaderMenuItem href="/stories/list">Stories</HeaderMenuItem>
            <HeaderMenuItem href="/trails/list">Trails</HeaderMenuItem>
            <HeaderMenuItem href="/contact">Support</HeaderMenuItem>
        </HeaderNavigation>
    </Header>
);
