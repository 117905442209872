import { createStore, createHook } from 'react-sweet-state';
import { defaultCompanyLocation } from '../constants/form.constants'

const Store = createStore({
    initialState: {
        user: {},
        userList: {},
        storyList: {},
        trailList: {},
        advertisementList: {},
        advertisementPinLocation: {
            lat: defaultCompanyLocation.latitude,
            lng: defaultCompanyLocation.longitude,
        },
        storyPinLocation: {
            lat: defaultCompanyLocation.latitude,
            lng: defaultCompanyLocation.longitude,
        },
        currentMediaUploadId: 0,
    },
    actions: {
        setUser: ({ data }) => ({ setState, getState }) => {
            setState({ user: data });
        },
        setUserList: ({ data }) => ({ setState, getState }) => {
            setState({ userList: data });
        },
        setStoryList: ({ data }) => ({ setState, getState }) => {
            setState({ storyList: data });
        },
        setTrailList: ({ data }) => ({ setState, getState }) => {
            setState({ trailList: data });
        },
        setAdvertisementList: ({ data }) => ({ setState, getState }) => {
            setState({ advertisementList: data });
        },
        setAdvertisementPinLocation: ({ location }) => ({ setState, getState }) => {
            setState({ advertisementPinLocation: location })
        },
        setStoryPinLocation: ({ location }) => ({ setState, getState }) => {
            setState({ storyPinLocation: location })
        },
        setCurrentMediaUploadId: ({ id }) => ({ setState, getState }) => {
            setState({ currentMediaUploadId: id })
        }
    },
    name: 'globalState',
});

const useGlobalState = createHook(Store);

export {
    useGlobalState,
};
