import React, { useState } from 'react';
import {
    Button,
    TextInput,
    Form,
    FormGroup,
} from 'carbon-components-react';
import { Column, Row } from "./Layout";
import { spacing } from "../constants/styles.constants";
import styles from './UserDetails.module.scss';
import Loading from "./Loading";

// eslint-disable-next-line
export default (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    return (
        <Row space={spacing.medium}>
            <Column space={spacing.medium}>
                <Form>
                    <FormGroup legendText={''}>
                        <Loading
                            isLoading={props.isLoading}
                            isSuccess={props.isSuccess}
                            isError={props.isError}
                            errorMessage={props.errorMessage}
                        />
                    </FormGroup>
                    <FormGroup legendText={''}>
                        <TextInput
                            helperText="Please enter your First Name"
                            id="firstName"
                            invalidText="First Name is Invalid."
                            labelText="First Name"
                            placeholder="Jane"
                            disabled={props.isLoading || props.isSuccess}
                            onChange={(event) => setFirstName(event.target.value)}
                        />
                    </FormGroup>
                    <FormGroup legendText={''}>
                        <TextInput
                            helperText="Please enter your Last Name"
                            id="lastName"
                            invalidText="Last Name is Invalid."
                            labelText="Last Name"
                            placeholder="Smith"
                            disabled={props.isLoading || props.isSuccess}
                            onChange={(event) => setLastName(event.target.value)}
                        />
                    </FormGroup>
                    <Row>
                        <Column>
                            <Button
                                kind="primary"
                                tabIndex={0}
                                type="submit"
                                disabled={props.isLoading || props.isSuccess}
                                onClick={() => {
                                    props.submit({ firstName, lastName })
                                }}

                            >
                                Submit
                            </Button>
                        </Column>
                        {props.isLoading && <Loading
                            className={styles.loaderMedium}
                            description="Loading"
                            withOverlay={false}
                        />}
                    </Row>
                </Form>
            </Column>
        </Row>
    )
}