import moment from 'moment';

const compareAlphabetically = (a, b) => {
    if (!a.toUpperCase || !b.toUpperCase) {
        return 0;
    }

    const nameA = a.toUpperCase();
    const nameB = b.toUpperCase();

    if (nameA < nameB) {
        return -1;
    }

    if (nameA > nameB) {
        return 1;
    }

    return 0;
}

const compareDates = (a, b) => {
    const dateFormat = 'MMMM Do YYYY, h:mm:ss a'
    const dateA = moment(a, dateFormat);
    const dateB = moment(b, dateFormat);

    if (dateA > dateB) {
        return -1;
    }

    return 1;
}

export {
    compareAlphabetically,
    compareDates,
}
