import React from 'react';
import styles from './Layout.module.scss';

const Container = ({ children }) => <div className={`bx--grid ${styles.container}`}>{children}</div>;

const Row = ({ children, space, className }) => {
  let spaceClass = '';
  if (space) {
    const spacer = `${space ? `-${space}` : ''}`;
    spaceClass = styles[`spacer${spacer}`];
  }
  return <div className={`bx--row ${className || ''} ${spaceClass}`}>{children}</div>;
};

const Column = ({ children, space, className }) => {
  let spaceClass = '';
  if (space) {
    const spacer = `${space ? `-${space}` : ''}`;
    spaceClass = styles[`spacer${spacer}`];
  }
  return <div className={`bx--col ${className || ''} ${spaceClass}`}>{children}</div>;
};

export { Container, Row, Column };
