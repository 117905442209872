import React, { useEffect, useState } from 'react';
import { spacing } from '../../constants/styles.constants';
import { Column, Container, Row } from '../../components/Layout';
import UsersList from "../../components/UsersList";
import Loading from "../../components/Loading";
import LogoHeading from "../../components/LogoHeading";
import { useGlobalState } from "../../hooks/useGlobalState";
import { genericRequest } from "../../utils/request";

// eslint-disable-next-line
export default () => {
    const [{ userList }, { setUserList }] = useGlobalState();
    const [requestStatus, setRequestStatus] = useState({
        isError: false,
        isLoading: false,
        isSuccess: false
    });

    useEffect(() => {
        async function getPageData() {
            setRequestStatus({ isLoading: true });
            const request = await genericRequest({ endpoint: 'getUserListDetails' });

            if (request.response) {
                setUserList({ data: request.response.data });
                return setRequestStatus({ isSuccess: true });
            }

            return setRequestStatus({ isError: request.error });
        }

        getPageData().then()
    }, [setRequestStatus, setUserList]);

    return (
        <Container>
            <LogoHeading/>
            <Loading isLoading={requestStatus.isLoading}
                     isError={requestStatus.isError}
                     isSuccess={requestStatus.isSuccess}
                     errorMessage={"We Couldn't List Users At This Time"}
                     successMessage={"We got the Users List"}
            >
                <Row space={spacing.large}>
                    <Column space={spacing.large}>
                        <UsersList userList={userList}/>
                    </Column>
                </Row>
            </Loading>
        </Container>
    );
};
