import React, { useEffect, useState } from "react";
import { adTypesHash } from "../constants/form.constants";

// eslint-disable-next-line
export default (props) => {
    const [totalAmount, setTotalAmount] = useState(0);

    const calculateTotalAmount = ({ advertisementTypeList }) => {
        let totalAmount = 0;

        for (let selectedAd of advertisementTypeList) {
            const foundAd = adTypesHash[selectedAd];

            totalAmount += foundAd.amount;
        }

        return totalAmount;
    }

    useEffect(() => {
        const { advertisementTypeList, discountAmount } = props;
        if (advertisementTypeList) {
            const finalAmount = calculateTotalAmount({ advertisementTypeList }) - discountAmount;
            setTotalAmount(finalAmount);
        }
    }, [props])

    return (
        <div>
            <span>You will be charged the Amount
                <span
                    style={{ fontWeight: 700 }}> ${(Math.round((totalAmount + Number.EPSILON) * 100) / 100).toFixed(2)} AUD </span>
                Monthly
            </span>
        </div>
    )
};
