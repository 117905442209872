import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Layout';
import LogoHeading from "../../components/LogoHeading";
import endpoints from "../../constants/endpoints.constants";
import TrailDetails from "../../components/TrailDetails";
import Loader from "../../components/Loading";
import { genericRequest, genericTrailsRequest } from "../../utils/request";
import { useHistory } from "react-router-dom";

// eslint-disable-next-line
export default (props) => {
    const history = useHistory();
    const [userData, setUserData] = useState({});
    const [trailData, setTrailData] = useState({});
    const [createdTrails, setCreatedTrails] = useState(false);
    const [storyList, setStoryList] = useState([]);

    const [requestTrailStatus, setRequestTrailStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestUserDataStatus, setRequestUserDataStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestTrailSaveStatus, setRequestTrailSaveStatus] = useState({
        isError: false, isLoading: false, isSuccess: false, errorMessage: '',
    });
    const [requestStoryList, setRequestStoryList] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });

    useEffect(() => {
        async function getUserData() {
            setRequestUserDataStatus({ isLoading: true });
            const request = await genericRequest({
                endpoint: 'getUserDetails',
                method: 'GET',
            });

            if (request.response) {
                setUserData(request.response.data);
                return setRequestUserDataStatus({ isSuccess: true });
            }

            return setRequestUserDataStatus({ isError: request.error });
        }

        async function getTrailData() {
            if (props.match.params.trailId) {
                setRequestTrailStatus({ isLoading: true });
                const request = await genericTrailsRequest({
                    endpoint: `user/trails/${props.match.params.trailId}`,
                    method: 'GET',
                });

                if (request.response) {
                    const reactTrailData = {
                        trailTitle: request.response.data.title,
                        trailDescription: request.response.data.description,
                        trailDifficulty: request.response.data.difficulty,
                        trailDistance: request.response.data.distance,
                        storyIdPlacementList: request.response.data.stories.map(story => story.id),
                        headerOneKey: request.response.data.header_images[0],
                        headerTwoKey: request.response.data.header_images[1],
                        agreedToTermsAndConditions: false,
                    }

                    setTrailData(reactTrailData);
                    return setRequestTrailStatus({ isSuccess: true });
                }

                return setRequestTrailStatus({ isError: request.error });
            } else {
                return setRequestTrailStatus({ isSuccess: true });
            }
        }

        async function getStoryList() {
            setRequestStoryList({ isLoading: true });
            const request = await genericRequest({
                endpoint: 'getAllStoryListDetails',
                method: 'GET',
            });

            if (request.response) {
                setStoryList(request.response.data);
                return setRequestStoryList({ isSuccess: true });
            }

            return setRequestStoryList({ isError: request.error });
        }

        getStoryList().then()
        getTrailData().then();
        getUserData().then();
    }, [props.match.params.trailId, setTrailData]);

    const submitUserDetails = async ({ formData, isEdit }) => {
        setRequestTrailSaveStatus({ isLoading: true });

        const request = await genericTrailsRequest({
            endpoint: 'trails',
            method: isEdit ? 'PUT' : 'POST',
            data: {
                id: isEdit ? parseInt(props.match.params.trailId) : 0,
                title: formData.trailTitle,
                description: formData.trailDescription,
                difficulty: formData.trailDifficulty,
                distance: formData.trailDistance,
                header_images: [
                    formData.headerOneKey,
                    formData.headerTwoKey,
                ],
                stories: formData.storyIdPlacementList.map(storyId => `${storyId}`),
                terms_and_conditions: formData.agreedToTermsAndConditions,
            },
        });

        if (request.error) {
            setRequestTrailSaveStatus({
                isLoading: false,
                isError: true,
                errorMessage: request.error.response ? request.error.response.data.errorMessage : request.error.toString()
            });
            return window.scrollTo(0, document.body.scrollHeight);
        }

        setRequestTrailSaveStatus({ isSuccess: true });
        setCreatedTrails(request.response)
        window.scrollTo(0, document.body.scrollHeight);

        if (isEdit) {
            setTimeout(
                () => {
                    history.push(endpoints.listTrails())
                },
                1000
            )
        }
    };

    return (
        <Container style={{ width: 200 }}>
            <LogoHeading/>
            <Loader
                isLoading={requestTrailStatus.isLoading || requestUserDataStatus.isLoading || requestStoryList.isLoading}
                isError={requestTrailStatus.isError || requestUserDataStatus.isError || requestStoryList.isError}
                isSuccess={requestTrailStatus.isSuccess && requestUserDataStatus.isSuccess && requestStoryList.isSuccess}
                errorMessage={"We Couldn't Load the Trails Details"}
                successMessage={"Loaded Trail Details"}
            >
                <TrailDetails
                    isLoading={requestTrailSaveStatus.isLoading}
                    isError={requestTrailSaveStatus.isError}
                    isSuccess={requestTrailSaveStatus.isSuccess}
                    errorMessage={requestTrailSaveStatus.errorMessage}
                    successMessage={"We Saved Your Details"}
                    trailData={trailData}
                    userData={userData}
                    storyList={storyList}
                    createdTrail={createdTrails}
                    submit={
                        (trailData, isEdit, agreedTermsConditions) => submitUserDetails(
                            {
                                formData: {
                                    ...trailData,
                                    agreedToTermsAndConditions: agreedTermsConditions
                                },
                                isEdit
                            }
                        )
                    }
                />
            </Loader>
        </Container>
    );
};
