import React from 'react';
import {
    DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell,
} from 'carbon-components-react';
import moment from 'moment'
import EditIcon from '@carbon/icons-react/lib/edit/16';
import style from './UsersList.module.scss'
import endpoints from '../constants/endpoints.constants'

// eslint-disable-next-line
export default (props) => {

    const headerData = [
        {
            header: "Name",
            key: "name",
        },
        {
            header: "Email",
            key: "email",
        },
        {
            header: "Created At",
            key: "created_at",
        },
        {
            header: "Edit",
            key: "edit",
        },
    ];

    const rowData = props.userList.map(user => (
        {
            id: user.id,
            name: `${user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : 'Note: Not Set'}`,
            email: user.email,
            created_at: moment(new Date(user.createdAt)).format('MMMM Do YYYY, h:mm:ss a'),
            edit: (<a target="_blank"
                      rel="noopener noreferrer"
                      href={endpoints.cognitoUsers({ cognitoUserId: user.cognitoUserId })}
            >
                <EditIcon/>
            </a>),
        }));

    return (
        <DataTable isSortable
                   headers={headerData}
                   rows={rowData}
                   render={({ rows, headers, getHeaderProps }) => (
                       <TableContainer title="Users List" description={
                           <p><br/>All Users that are able to access Yesterday Stories Admin</p>
                       }>
                           <Table useZebraStyles size='normal'>
                               <TableHead>
                                   <TableRow>
                                       {headers.map(header => (
                                           <TableHeader {...getHeaderProps({ header })}>
                                               {header.header}
                                           </TableHeader>
                                       ))}
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                   {rows.map(row => (
                                       <TableRow key={row.id}>
                                           {row.cells.map(cell => (
                                               <TableCell className={cell.id.includes("edit") ? style.centreAlign : ''}
                                                          key={cell.id}>{cell.value}</TableCell>
                                           ))}
                                       </TableRow>
                                   ))}
                               </TableBody>
                           </Table>
                       </TableContainer>)}
        />
    );
}
