import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Layout';
import LogoHeading from "../../components/LogoHeading";
import AdvertisementDetails from "../../components/AdvertisementDetails";
import Loader from "../../components/Loading";
import { genericRequest } from "../../utils/request";

// eslint-disable-next-line
export default (props) => {
    const [userData, setUserData] = useState({});
    const [adData, setAdData] = useState({});
    const [storyList, setStoryList] = useState([]);
    const [createdAd, setCreatedAd] = useState(false);
    const [requestAdStatus, setRequestAdStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestStoryList, setRequestStoryList] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestUserDataStatus, setRequestUserDataStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestAdSaveStatus, setRequestAdSaveStatus] = useState({
        isError: false, isLoading: false, isSuccess: false, errorMessage: '',
    });
    const urlParams = new URLSearchParams(props.location.search);
    const promoCodeParam = urlParams.get('promo');
    const storyLockParam = urlParams.get('storyLock');
    const storyIdParam = urlParams.get('storyId');

    useEffect(() => {
        async function getUserData() {
            setRequestUserDataStatus({ isLoading: true });
            const request = await genericRequest({
                endpoint: 'getUserDetails',
                method: 'GET',
            });

            if (request.response) {
                setUserData(request.response.data);
                return setRequestUserDataStatus({ isSuccess: true });
            }

            return setRequestUserDataStatus({ isError: request.error });
        }

        async function getAdData() {
            if (props.match.params.advertisementId) {
                setRequestAdStatus({ isLoading: true });
                const request = await genericRequest({
                    endpoint: 'getAdvertisementDetails',
                    method: 'POST',
                    data: {
                        advertisementId: props.match.params.advertisementId
                    }
                });

                if (request.response) {
                    setAdData(request.response.data);
                    return setRequestAdStatus({ isSuccess: true });
                }

                return setRequestAdStatus({ isError: request.error });
            } else {
                return setRequestAdStatus({ isSuccess: true });
            }
        }

        async function getStoryList() {
            setRequestStoryList({ isLoading: true });
            const request = await genericRequest({
                endpoint: 'getAllStoryListDetails',
                method: 'GET',
            });

            if (request.response) {
                setStoryList(request.response.data);
                return setRequestStoryList({ isSuccess: true });
            }

            return setRequestStoryList({ isError: request.error });
        }

        getStoryList().then()
        getAdData().then();
        getUserData().then();
    }, [props.match.params.advertisementId, setAdData]);

    const submitUserDetails = async ({ formData, needsPayment, isEdit }) => {
        setRequestAdSaveStatus({ isLoading: true });

        const request = await genericRequest({
            endpoint: isEdit ? 'editAdvertisement' : 'createAdvertisement',
            method: "POST",
            data: {
                ...formData,
                advertisementId: props.match.params.advertisementId,
            },
        });

        if (request.error) {
            setRequestAdSaveStatus({
                isLoading: false,
                isError: true,
                errorMessage: request.error.response ? request.error.response.data.errorMessage : request.error.toString()
            });

            return window.scrollTo(0, document.body.scrollHeight);
        }

        setRequestAdSaveStatus({ isSuccess: true });
        setCreatedAd(request.response)
        return window.scrollTo(0, document.body.scrollHeight);
    };

    return (
        <Container style={{ width: 200 }}>
            <LogoHeading/>
            <Loader
                isLoading={requestAdStatus.isLoading || requestUserDataStatus.isLoading || requestStoryList.isLoading}
                isError={requestAdStatus.isError || requestUserDataStatus.isError || requestStoryList.isError}
                isSuccess={requestAdStatus.isSuccess && requestUserDataStatus.isSuccess && requestStoryList.isSuccess}
                errorMessage={"We Couldn't Load Story and Advertisement Details"}
                successMessage={"Loaded Stories and Advertisement"}
            >
                <AdvertisementDetails
                    isLoading={requestAdSaveStatus.isLoading}
                    isError={requestAdSaveStatus.isError}
                    isSuccess={requestAdSaveStatus.isSuccess}
                    errorMessage={requestAdSaveStatus.errorMessage}
                    successMessage={"We Saved Your Details"}
                    adData={adData}
                    userData={userData}
                    storyList={storyList}
                    createdAd={createdAd}
                    promoCode={promoCodeParam}
                    storyLockParam={storyLockParam}
                    storyIdParam={storyIdParam}
                    submit={
                        (adData, needsPayment, isEdit) => submitUserDetails(
                            { formData: adData, needsPayment, isEdit }
                        )
                    }
                />
            </Loader>
        </Container>
    );
};
