import React, { useState, useEffect } from 'react';
import {
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Toggle,
    TableToolbar,
    TableToolbarContent,
    Button,
    InlineNotification,
    Link
} from 'carbon-components-react';
import { useHistory, withRouter } from "react-router-dom";
import EditIcon from '@carbon/icons-react/lib/edit/16';
import MapIcon from '@carbon/icons-react/lib/map/16';
import LinkIcon from '@carbon/icons-react/lib/link/16';
import ImageIcon from '@carbon/icons-react/lib/image/16';
import BinIcon from '@carbon/icons-react/lib/trash-can/16';
import CheckmarkOutlineIcon from '@carbon/icons-react/lib/checkmark--outline/16';
import MisuseOutlineIcon from '@carbon/icons-react/lib/misuse--outline/16';
import style from './AdvertisementsList.module.scss'
import endpoints from '../constants/endpoints.constants'
import { adTypes } from '../constants/form.constants'
import { genericRequest } from "../utils/request";
import Loader from "./Loading";

export default withRouter((props) => {
    const history = useHistory();
    const [showDeleteSupportMessage, setShowDeleteSupportMessage] = useState(false);
    const [requestStatusEdit, setRequestStatusEdit] = useState([]);
    const [requestStatusDelete, setRequestStatusDelete] = useState({
        isError: false,
        isSuccess: false,
        isLoading: false,
    });

    const toggleApproval = async ({ approvalStatus, approvalListId, advertId, event }) => {
        requestStatusEdit[approvalListId] = { isLoading: true }
        setRequestStatusEdit(previousState => {
            previousState[approvalListId] = { isLoading: true }
            return [...previousState]
        })

        const request = await genericRequest({
            endpoint: 'toggleAdvertisementApproval',
            method: "POST",
            data: {
                advertisementId: advertId,
                approvalStatus,
            },
        });

        if (request.error) {
            event.target.checked = !approvalStatus
        }

        return setRequestStatusEdit(previousState => {
            previousState[approvalListId] = { isLoading: false }
            return [...previousState]
        })
    };

    const deleteAdvertisement = async ({ advertisement }) => {
        if (!window.confirm("Do you really want to delete?")) {
            return false
        }

        setRequestStatusDelete({ isLoading: true });

        const request = await genericRequest({
            endpoint: 'deleteAdvertisement',
            method: "POST",
            data: { advertisementId: advertisement.id },
        });

        if (request.error) {
            return setRequestStatusDelete({ isError: true, isLoading: false });
        }

        props.advertisementList.splice(props.advertisementList.findIndex(advert => advert.id === advertisement.id), 1)
        setRequestStatusDelete({ isSuccess: true, isLoading: false });
        return setShowDeleteSupportMessage(advertisement)
    };

    useEffect(() => {
        const requestStatusBuilder = [];
        props.advertisementList.forEach(() => {
            requestStatusBuilder.push({ isLoading: false })
        })

        setShowDeleteSupportMessage(false)
        setRequestStatusEdit(requestStatusBuilder)
    }, [props.advertisementList, history])

    const headerData = [
        {
            header: "Name",
            key: "ad_name",
        },
        {
            header: "Company Logo",
            key: "company_logo",
        },
        {
            header: "Company Name",
            key: "company_name",
        },
        {
            header: "Location",
            key: "ad_location",
        },
        {
            header: "Link",
            key: "ad_link",
        },
        {
            header: "Type",
            key: "ad_type",
        },
        {
            header: "Stories List",
            key: "stories_list",
        },
        {
            header: "Paid",
            key: "paid",
        },
        {
            header: "Edit",
            key: "edit",
        },
        {
            header: "Delete",
            key: "deleteAd",
        },
    ];

    if (props.userData.isAdmin) {
        headerData.push({
            header: "Approved",
            key: "approval",
        })
    }

    return (
        <>
            <Loader isLoading={requestStatusDelete.isLoading}
                    isError={requestStatusDelete.isError}
                    isSuccess={requestStatusDelete.isSuccess}
                    errorMessage={"We Couldn't Delete the Advertisement"}
                    successMessage={"Deleted Advertisement"}
            />
            <DataTable isSortable
                       headers={headerData}
                       rows={
                           props.advertisementList.map((advert, key) => (
                               {
                                   id: advert.id.toString(),
                                   ad_name: advert.advertisementName,
                                   ad_location: (
                                       <a target="_blank"
                                          rel="noopener noreferrer"
                                          href={endpoints.googleMaps({
                                              latitude: advert.companyLatitude,
                                              longitude: advert.companyLongitude
                                          })}
                                       >
                                           <MapIcon/>
                                       </a>
                                   ),
                                   ad_link: (
                                       <a target="_blank"
                                          rel="noopener noreferrer"
                                          href={advert.advertisementLink}
                                       >
                                           <LinkIcon/>
                                       </a>
                                   ),
                                   ad_type: adTypes.find(type => type.databaseId === advert.advertisementTypeList) ?
                                       adTypes.find(type => type.databaseId === advert.advertisementTypeList).text : "Advert",
                                   company_name: advert.companyName,
                                   company_logo: (
                                       <a target="_blank"
                                          rel="noopener noreferrer"
                                          href={endpoints.mediaUpload({ objectId: advert.Medium.objectKey })}
                                       >
                                           <ImageIcon/>
                                       </a>
                                   ),
                                   stories_list: (
                                       <span>Listed on {advert.Stories.length} Stories</span>
                                   ),
                                   approval: (
                                       <div>
                                           <Toggle
                                               size="sm"
                                               aria-label="Approval Toggle"
                                               defaultToggled={advert.isApproved}
                                               id={`approval-toggle-${advert.id}`}
                                               labelText={''}
                                               disabled={requestStatusEdit[key] ? requestStatusEdit[key].isLoading : false}
                                               onClick={async (event) => {
                                                   event.persist()
                                                   await toggleApproval({
                                                       advertId: advert.id,
                                                       approvalListId: key,
                                                       approvalStatus: event.target.checked,
                                                       event
                                                   })
                                               }}
                                           />
                                       </div>
                                   ),
                                   paid: (advert.isPaid ? <CheckmarkOutlineIcon/> : <MisuseOutlineIcon/>),
                                   edit: (
                                       <button
                                           className={style.styleLessButton}
                                           rel="noopener noreferrer"
                                           onClick={() => history.push(endpoints.advertisementDetails({ advertisementId: advert.id }))}
                                       >
                                           <EditIcon/>
                                       </button>
                                   ),
                                   deleteAd: (
                                       <button
                                           className={style.styleLessButton}
                                           rel="noopener noreferrer"
                                           onClick={() => deleteAdvertisement({ advertisement: advert })}
                                       >
                                           <BinIcon/>
                                       </button>
                                   ),
                               })
                           )}
                       render={({ rows, headers, getHeaderProps }) => (
                           <TableContainer
                               title="Advertisement List"
                               description={
                                   <>
                                       <span><br/>Your Advertisements Will Be Shown Here</span>
                                       {showDeleteSupportMessage && (<InlineNotification
                                           kind={'warning'}
                                           iconDescription="Important"
                                           subtitle={<span>Please contact <Link
                                               target="_blank"
                                               href={`mailto:support@yesterdaystories.com.au?subject=Yesterday Stories Support&body=Please include Subscription ID ${showDeleteSupportMessage.stripeSubscriptionId} in your email thank you.`}>
                                                support@yesterdaystories.com.au
                                           </Link> to cancel your subscription. Your Subscription Id is {showDeleteSupportMessage.stripeSubscriptionId}
                                           </span>}
                                           title="Important"
                                           hideCloseButton={true}
                                       />)}
                                   </>
                               }>
                               <TableToolbar>
                                   <TableToolbarContent>
                                       <Button
                                           tabIndex={1}
                                           onClick={() => history.push(endpoints.createAdvertisement())}
                                           size="small"
                                           kind="primary">
                                           Add New Advertisement
                                       </Button>
                                   </TableToolbarContent>
                               </TableToolbar>
                               <Table useZebraStyles size='normal'>
                                   <TableHead>
                                       <TableRow>
                                           {headers.map(header => (
                                               <TableHeader {...getHeaderProps({ header })}>
                                                   {header.header}
                                               </TableHeader>
                                           ))}
                                       </TableRow>
                                   </TableHead>
                                   <TableBody>
                                       {rows.map(row => (
                                           <TableRow key={row.id}>
                                               {row.cells.map(cell => (
                                                   <TableCell className={
                                                       cell.id.includes("ad_location") ||
                                                       cell.id.includes("ad_link") ||
                                                       cell.id.includes("company_logo") ||
                                                       cell.id.includes("approval") ||
                                                       cell.id.includes("paid") ||
                                                       cell.id.includes("deleteAd") ||
                                                       cell.id.includes("edit")
                                                           ? style.centreAlign : ''
                                                   } key={cell.id}>{cell.value}</TableCell>
                                               ))}
                                           </TableRow>
                                       ))}
                                   </TableBody>
                               </Table>
                           </TableContainer>
                       )}
            />
        </>
    );
});
