import React, { useState, useEffect } from 'react';
import {
    DataTable,
    TableContainer,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Toggle,
    TableToolbar,
    TableToolbarContent,
    Button,
    Loading,
} from 'carbon-components-react';
import { useHistory, withRouter } from "react-router-dom";
import EditIcon from '@carbon/icons-react/lib/edit/16';
import MapIcon from '@carbon/icons-react/lib/map/16';
import LinkIcon from '@carbon/icons-react/lib/link/16';
import ImageIcon from '@carbon/icons-react/lib/image/16';
import VideoIcon from '@carbon/icons-react/lib/video/16';
import BinIcon from '@carbon/icons-react/lib/trash-can/16';
import MisuseOutlineIcon from '@carbon/icons-react/lib/misuse--outline/16';
import moment from 'moment'
import style from './AdvertisementsList.module.scss'
import endpoints from '../constants/endpoints.constants'
import { genericRequest } from "../utils/request";
import { videoProcessingStatuses } from "../constants/form.constants"
import { compareAlphabetically, compareDates } from "../utils/sort";
import Loader from "./Loading";

export default withRouter((props) => {
    const history = useHistory();
    const [requestStatusEdit, setRequestStatusEdit] = useState([]);
    const [requestStatusEditAds, setRequestStatusEditAds] = useState([]);
    const [requestStatusDelete, setRequestStatusDelete] = useState({
        isError: false,
        isSuccess: false,
        isLoading: false,
    });

    const toggleApproval = async ({ approvalStatus, approvalListId, storyId, event, isAds }) => {
        if (isAds) {
            requestStatusEditAds[approvalListId] = { isLoading: true }
        } else {
            requestStatusEdit[approvalListId] = { isLoading: true }
        }

        if (isAds) {
            setRequestStatusEditAds(previousState => {
                previousState[approvalListId] = { isLoading: true }
                return [...previousState]
            })
        } else {
            setRequestStatusEdit(previousState => {
                previousState[approvalListId] = { isLoading: true }
                return [...previousState]
            })
        }

        const request = await genericRequest({
            endpoint: isAds ? 'toggleStoryAdApproval' : 'toggleStoryApproval',
            method: "POST",
            data: {
                storyId,
                approvalStatus,
            },
        });

        if (request.error) {
            event.target.checked = !approvalStatus
        }

        if (isAds) {
            return setRequestStatusEditAds(previousState => {
                previousState[approvalListId] = { isLoading: false }
                return [...previousState]
            })
        } else {
            return setRequestStatusEdit(previousState => {
                previousState[approvalListId] = { isLoading: false }
                return [...previousState]
            })
        }
    };

    const deleteStory = async ({ storyId }) => {
        if (!window.confirm("Do you really want to delete?")) {
            return false
        }

        setRequestStatusDelete({ isLoading: true });

        const request = await genericRequest({
            endpoint: 'deleteStory',
            method: "POST",
            data: { storyId },
        });

        if (request.error) {
            return setRequestStatusDelete({ isError: true, isLoading: false });
        }

        props.storyList.splice(props.storyList.findIndex(story => story.id === storyId), 1)
        return setRequestStatusDelete({ isSuccess: true, isLoading: false });
    };

    useEffect(() => {
        const requestStatusBuilder = [];
        props.storyList.forEach(() => {
            requestStatusBuilder.push({ isLoading: false })
        })

        setRequestStatusEdit(requestStatusBuilder)
    }, [props.storyList, history])

    const headerData = [
        {
            header: "Name",
            key: "story_name",
        },
        {
            header: "Modified",
            key: "modified_date",
        },
        {
            header: "Processed Story",
            key: "story_media_processed",
        },
        {
            header: "Story Thumbnail",
            key: "story_thumbnail",
        },
        {
            header: "Video Status",
            key: "video_status",
        },
        {
            header: "Category",
            key: "story_category",
        },
        {
            header: "Location",
            key: "story_location",
        },
        {
            header: "Link",
            key: "story_link",
        },
        {
            header: "Sharing Video",
            key: "story_media_shortened_processed",
        },
        {
            header: "Edit",
            key: "edit",
        },
        {
            header: "Delete",
            key: "deleteStory",
        },
    ];

    if (props.userData.isAdmin) {
        headerData.push(
            {
                header: "Approved",
                key: "approval",
            },
            {
                header: "Ads Enabled",
                key: "approval_ads",
            }
        )
    }

    const compare = (cellA, cellB, rowId) => {
        if (rowId === "modified_date") {
            return compareDates(cellA, cellB);
        }

        return compareAlphabetically(cellA, cellB);
    }

    return (
        <>
            <Loader isLoading={requestStatusDelete.isLoading}
                    isError={requestStatusDelete.isError}
                    isSuccess={requestStatusDelete.isSuccess}
                    errorMessage={"We Couldn't Delete the Advertisement"}
                    successMessage={"Deleted Advertisement"}
            />
            <DataTable isSortable
                       headers={headerData}
                       sortRow={(cellA, cellB, data) => {
                           if (data.sortDirection === data.sortStates.DESC) {
                               return compare(cellB, cellA, data.key);
                           }

                           return compare(cellA, cellB, data.key);
                       }}
                       rows={
                           props.storyList.map((story, key) => {
                                   const processingFinished = (story.videoProcessingStatus === videoProcessingStatuses.COMPLETED.databaseName) &&
                                       (story.shortVideoProcessingStatus === videoProcessingStatuses.COMPLETED.databaseName)

                                   return (
                                       {
                                           id: story.id.toString(),
                                           story_name: story.storyName,
                                           modified_date: `${moment(new Date(story.updatedAt)).format('MMMM Do YYYY, h:mm:ss a')}`,
                                           story_category: `${story.storyCategories.length} ${story.storyCategories.length > 1 ? 'Categories' : 'Category'}`,
                                           story_location: (
                                               <a target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={endpoints.googleMaps({
                                                      latitude: story.storyLatitude,
                                                      longitude: story.storyLongitude
                                                  })}
                                               >
                                                   <MapIcon/>
                                               </a>
                                           ),
                                           story_link: (
                                               <a target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={endpoints.sharePage({ objectId: story.storyStaticSharingId })}
                                               >
                                                   <LinkIcon/>
                                               </a>
                                           ),
                                           story_thumbnail: (
                                               <a target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={endpoints.mediaUpload({ objectId: story.thumbnailMedia.objectKey })}
                                               >
                                                   <ImageIcon/>
                                               </a>
                                           ),
                                           video_status: (
                                               <>
                                                   <span>
                                                       {!processingFinished &&
                                                       <Loading small={true}
                                                                description="Still Processing"
                                                                withOverlay={false}
                                                       />}
                                                   </span>
                                                   <span>{videoProcessingStatuses[story.videoProcessingStatus].friendlyName.toString()}</span>
                                               </>
                                           ),
                                           story_media_processed: (
                                               <button
                                                   disabled={!processingFinished}
                                                   className={style.styleLessButton}
                                                   rel="noopener noreferrer"
                                                   onClick={() => window.open(endpoints.mediaUpload({ objectId: story.storyMediaProcessed ? story.storyMediaProcessed.objectKey : '404' }), "_newtab")}
                                               >
                                                   {processingFinished ? <VideoIcon/> : <MisuseOutlineIcon/>}
                                               </button>
                                           ),
                                           story_media_shortened_processed: (
                                               <button
                                                   disabled={!processingFinished}
                                                   className={style.styleLessButton}
                                                   rel="noopener noreferrer"
                                                   onClick={() => window.open(endpoints.mediaUpload({ objectId: story.shortStoryMedia ? story.shortStoryMedia.objectKey : '404' }), "_newtab")}
                                               >
                                                   {processingFinished ? <VideoIcon/> : <MisuseOutlineIcon/>}
                                               </button>
                                           ),
                                           approval: (
                                               <div>
                                                   <Toggle
                                                       size="sm"
                                                       aria-label="Approval Toggle"
                                                       defaultToggled={story.isApproved}
                                                       id={`approval-toggle-${story.id}`}
                                                       labelText={''}
                                                       disabled={requestStatusEdit[key] ? requestStatusEdit[key].isLoading : false}
                                                       onClick={async (event) => {
                                                           event.persist()
                                                           await toggleApproval({
                                                               storyId: story.id,
                                                               approvalListId: key,
                                                               approvalStatus: event.target.checked,
                                                               event,
                                                               isAds: false,
                                                           })
                                                       }}
                                                   />
                                               </div>
                                           ),
                                           approval_ads: (
                                               <div>
                                                   <Toggle
                                                       size="sm"
                                                       aria-label="Approval Toggle Show Ads"
                                                       defaultToggled={story.isApprovedAds}
                                                       id={`approval-toggle-${story.id}-ads`}
                                                       labelText={''}
                                                       disabled={requestStatusEdit[key] ? requestStatusEdit[key].isLoading : false}
                                                       onClick={async (event) => {
                                                           event.persist()
                                                           await toggleApproval({
                                                               storyId: story.id,
                                                               approvalListId: key,
                                                               approvalStatus: event.target.checked,
                                                               event,
                                                               isAds: true,
                                                           })
                                                       }}
                                                   />
                                               </div>
                                           ),
                                           edit: (
                                               <button
                                                   disabled={!processingFinished}
                                                   className={style.styleLessButton}
                                                   rel="noopener noreferrer"
                                                   onClick={() => history.push(endpoints.storyDetails({ storyId: story.id }))}
                                               >
                                                   {processingFinished ? <EditIcon/> : <MisuseOutlineIcon/>}
                                               </button>
                                           ),
                                           deleteStory: (
                                               <button
                                                   className={style.styleLessButton}
                                                   rel="noopener noreferrer"
                                                   onClick={() => deleteStory({ storyId: story.id })}
                                               >
                                                   <BinIcon/>
                                               </button>
                                           ),
                                       }
                                   )
                               }
                           )
                       }
                       render={({ rows, headers, getHeaderProps }) => (
                           <TableContainer
                               title="Story List"
                               description={
                                   <span><br/>Your Stories Will Be Shown Here</span>
                               }>
                               <TableToolbar>
                                   <TableToolbarContent>
                                       <Button
                                           tabIndex={1}
                                           onClick={() => history.push(endpoints.createStory())}
                                           size="small"
                                           kind="primary">
                                           Add New Story
                                       </Button>
                                       <TableToolbarSearch onChange={props.storyListFilter}/>
                                   </TableToolbarContent>
                               </TableToolbar>
                               <Table useZebraStyles size='normal'>
                                   <TableHead>
                                       <TableRow>
                                           {headers.map(header => (
                                               <TableHeader {...getHeaderProps({ header })}>
                                                   {header.header}
                                               </TableHeader>
                                           ))}
                                       </TableRow>
                                   </TableHead>
                                   <TableBody>
                                       {rows.map(row => (
                                           <TableRow key={row.id}>
                                               {row.cells.map(cell => (
                                                   <TableCell className={
                                                       cell.id.includes("story_location") ||
                                                       cell.id.includes("story_link") ||
                                                       cell.id.includes("story_thumbnail") ||
                                                       cell.id.includes("approval") ||
                                                       cell.id.includes("deleteStory") ||
                                                       cell.id.includes("story_media_processed") ||
                                                       cell.id.includes("story_media_shortened_processed") ||
                                                       cell.id.includes("edit")
                                                           ? style.centreAlign : ''
                                                   } key={cell.id}>{cell.value}</TableCell>
                                               ))}
                                           </TableRow>
                                       ))}
                                   </TableBody>
                               </Table>
                           </TableContainer>
                       )}
            />
        </>
    );
});
