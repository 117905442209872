import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './pages/home/LandingPage';
import ListUsers from './pages/user/ListUsers';
import AuthCallback from "./pages/home/AuthCallback";
import ListAdvertisements from "./pages/advertisements/ListAdvertisements";
import CreateStories from "./pages/stories/CreateStories";
import ListStories from "./pages/stories/ListStories";
import CreateTrails from "./pages/trails/CreateTrails";
import ListTrails from "./pages/trails/ListTrails";
import CreateAdvertisements from "./pages/advertisements/CreateAdvertisements";
import Contact from "./pages/contact/Contact";
import Footer from "./components/Footer";

const routing = () => (
    <Router>
        <Header/>
        <Switch>
            <Route exact path="/" component={LandingPage}/>
            <Route path="/advertisements/create" component={CreateAdvertisements}/>
            <Route path="/advertisements/list" component={ListAdvertisements}/>
            <Route path="/advertisements/details/:advertisementId" component={CreateAdvertisements}/>
            <Route path="/stories/create" component={CreateStories}/>
            <Route path="/stories/list" component={ListStories}/>
            <Route path="/stories/details/:storyId" component={CreateStories}/>
            <Route path="/trails/create" component={CreateTrails}/>
            <Route path="/trails/list" component={ListTrails}/>
            <Route path="/trails/details/:trailId" component={CreateTrails}/>
            <Route path="/users/list" component={ListUsers}/>
            <Route path="/auth/callback" component={AuthCallback}/>
            <Route path="/contact" component={Contact}/>
            <Redirect to="/"/>
        </Switch>
        <Footer/>
    </Router>
);

export default routing
