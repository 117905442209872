import React, { useEffect, useState } from 'react';
import { Container } from '../../components/Layout';
import LogoHeading from "../../components/LogoHeading";
import endpoints from "../../constants/endpoints.constants";
import StoryDetails from "../../components/StoryDetails";
import Loader from "../../components/Loading";
import { genericRequest } from "../../utils/request";
import { useHistory } from "react-router-dom";

// eslint-disable-next-line
export default (props) => {
    const history = useHistory();
    const [userData, setUserData] = useState({});
    const [storyData, setStoryData] = useState({});
    const [createdStory, setCreatedStory] = useState(false);

    const [requestStoryStatus, setRequestStoryStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestUserDataStatus, setRequestUserDataStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });
    const [requestStorySaveStatus, setRequestStorySaveStatus] = useState({
        isError: false, isLoading: false, isSuccess: false, errorMessage: '',
    });

    useEffect(() => {
        async function getUserData() {
            setRequestUserDataStatus({ isLoading: true });
            const request = await genericRequest({
                endpoint: 'getUserDetails',
                method: 'GET',
            });

            if (request.response) {
                setUserData(request.response.data);
                return setRequestUserDataStatus({ isSuccess: true });
            }

            return setRequestUserDataStatus({ isError: request.error });
        }

        async function getstoryData() {
            if (props.match.params.storyId) {
                setRequestStoryStatus({ isLoading: true });
                const request = await genericRequest({
                    endpoint: 'getStoryDetails',
                    method: 'POST',
                    data: {
                        storyId: props.match.params.storyId
                    }
                });

                if (request.response) {
                    request.response.data.storyLatitude = parseFloat(request.response.data.storyLatitude)
                    request.response.data.storyLongitude = parseFloat(request.response.data.storyLongitude)
                    setStoryData(request.response.data);
                    return setRequestStoryStatus({ isSuccess: true });
                }

                return setRequestStoryStatus({ isError: request.error });
            } else {
                return setRequestStoryStatus({ isSuccess: true });
            }
        }

        getstoryData().then();
        getUserData().then();
    }, [props.match.params.storyId, setStoryData]);

    const submitUserDetails = async ({ formData, isEdit }) => {
        setRequestStorySaveStatus({ isLoading: true });

        const request = await genericRequest({
            endpoint: isEdit ? 'editStory' : 'createStory',
            method: "POST",
            data: {
                ...formData,
                storyId: props.match.params.storyId,
            },
        });

        if (request.error) {
            setRequestStorySaveStatus({
                isLoading: false,
                isError: true,
                errorMessage: request.error.response ? request.error.response.data.errorMessage : request.error.toString()
            });
            return window.scrollTo(0,document.body.scrollHeight);
        }

        setRequestStorySaveStatus({ isSuccess: true });
        setCreatedStory(request.response)
        window.scrollTo(0,document.body.scrollHeight);

        if (isEdit) {
            setTimeout(
                () => {
                    history.push(endpoints.listStory())
                },
                1000
            )
        }
    };

    return (
        <Container style={{ width: 200 }}>
            <LogoHeading/>
            <Loader
                isLoading={requestStoryStatus.isLoading || requestUserDataStatus.isLoading}
                isError={requestStoryStatus.isError || requestUserDataStatus.isError}
                isSuccess={requestStoryStatus.isSuccess && requestUserDataStatus.isSuccess}
                errorMessage={"We Couldn't Load the Story Details"}
                successMessage={"Loaded Story Details"}
            >
                <StoryDetails
                    isLoading={requestStorySaveStatus.isLoading}
                    isError={requestStorySaveStatus.isError}
                    isSuccess={requestStorySaveStatus.isSuccess}
                    errorMessage={requestStorySaveStatus.errorMessage}
                    successMessage={"We Saved Your Details"}
                    storyData={storyData}
                    userData={userData}
                    createdStory={createdStory}
                    submit={
                        (storyData, isEdit, agreedTermsConditions) => submitUserDetails(
                            {
                                formData: {
                                    ...storyData,
                                    agreedToTermsAndConditions: agreedTermsConditions
                                },
                                isEdit
                            }
                        )
                    }
                />
            </Loader>
        </Container>
    )
        ;
};
