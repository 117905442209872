import React, { useState } from 'react';
import { Marker, GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";
import keys from "../constants/keys.constants"
import style from "./MapMarker.module.scss"
import { useGlobalState } from "../hooks/useGlobalState";

const libraries = ["places"];

// eslint-disable-next-line
export default (props) => {
    const { isAdvertisement } = props
    const [autoComplete, setAutoComplete] = useState();
    const [mapZoomLevel, setMapZoomLevel] = useState(10);
    const [{ advertisementPinLocation }, { setAdvertisementPinLocation }] = useGlobalState();
    const [{ storyPinLocation }, { setStoryPinLocation }] = useGlobalState();

    const autoCompletePlaceSelected = () => {
        setAdvertisementPinLocation({
            location: {
                lat: autoComplete.getPlace().geometry.location.lat(),
                lng: autoComplete.getPlace().geometry.location.lng(),
            }
        });
        setStoryPinLocation({
            location: {
                lat: autoComplete.getPlace().geometry.location.lat(),
                lng: autoComplete.getPlace().geometry.location.lng(),
            }
        });
        setMapZoomLevel(18);
    };

    const pinDragSetLocation = (location) => {
        const parsedLocation = {
            lat: location.latLng.lat(),
            lng: location.latLng.lng(),
        }

        props.onChange(parsedLocation)
        setAdvertisementPinLocation({ location: parsedLocation });
        setStoryPinLocation({ location: parsedLocation });
    };

    return (
        <LoadScript
            googleMapsApiKey={keys.googleMapsApiKey}
            libraries={libraries}
        >
            <GoogleMap
                mapContainerClassName={style.mapContainer}
                zoom={mapZoomLevel}
                center={isAdvertisement ? advertisementPinLocation : storyPinLocation}
            >
                <Autocomplete
                    onLoad={(autoComplete) => setAutoComplete(autoComplete)}
                    onPlaceChanged={autoCompletePlaceSelected}
                >
                    <input
                        type="text"
                        placeholder={props.searchText}
                        className={style.autoCompleteBox}
                    />
                </Autocomplete>
                <Marker
                    position={isAdvertisement ? advertisementPinLocation : storyPinLocation}
                    onDragEnd={pinDragSetLocation}
                    draggable={!props.isFormDisabled}
                />
            </GoogleMap>
        </LoadScript>
    )
}
