import React, { useEffect, useState } from "react";
import { Column, Row } from "./Layout";
import { spacing } from "../constants/styles.constants";
import { Button, FileUploader, Tag } from "carbon-components-react";
import style from "./MediaUpload.module.scss"
import { genericRequest, uploadRequest } from "../utils/request";
import Loading from "./Loading";
import { useGlobalState } from "../hooks/useGlobalState";

const uploadStatus = {
    edit: "edit",
    uploading: "uploading",
    complete: "complete",
}

// eslint-disable-next-line
export default (props) => {
    const [fileUrl, setFileUrl] = useState('');
    const [fileBlob, setFileBlob] = useState(new Blob());
    const [contentType, setContentType] = useState('');
    const [requestCreateUserMedia, setRequestCreateUserMedia] = useState({
        uploadStatus: uploadStatus.edit, isError: false, isLoading: false, isSuccess: false,
    });
    const [, { setCurrentMediaUploadId }] = useGlobalState();

    useEffect(() => {
        if (props.existingFileUrl) {
            setFileUrl(props.existingFileUrl)
        }
    }, [props.existingFileUrl])

    const fileSelected = (event) => {
        props.selectFileChange('')
        setRequestCreateUserMedia({
            uploadStatus: uploadStatus.edit, isError: false, isLoading: false, isSuccess: false,
        })

        setFileBlob(event.target.files[0])
        setFileUrl(URL.createObjectURL(event.target.files[0]))
        setContentType(event.target.files[0].type)
    }

    const parseContentType = ({ rawContentType }) => {
        if (rawContentType.includes("quicktime")) {
            return rawContentType.replace("quicktime", "mp4")
        }

        return rawContentType
    }

    const fileUpload = async () => {
        setRequestCreateUserMedia({
            uploadStatus: uploadStatus.uploading, isError: false, isLoading: true, isSuccess: false,
        })
        props.isUploading(true);

        const rawContentType = parseContentType({
            rawContentType: contentType.substr(contentType.lastIndexOf("/") + 1)
        })

        const uploadUrl = await genericRequest({
            endpoint: 'createUserMedia', method: 'POST', data: {
                format: rawContentType,
                contentType: contentType
            }
        })

        if (uploadUrl.error) {
            props.isUploading(false);
            return setRequestCreateUserMedia({
                uploadStatus: uploadStatus.complete, isError: true, isLoading: false, isSuccess: false,
            })
        }

        if (props.onChange) {
            props.onChange(uploadUrl.response.data.uploadId)
        }

        if (props.onChangeWholeData) {
            props.onChangeWholeData(uploadUrl.response.data)
        }

        setCurrentMediaUploadId(uploadUrl.response.data.uploadId);
        const uploadResponse = await uploadRequest({
            endpoint: uploadUrl.response.data.uploadUrl,
            data: fileBlob,
            contentType
        })

        if (uploadResponse.error) {
            props.isUploading(false);
            return setRequestCreateUserMedia({
                uploadStatus: uploadStatus.complete, isError: true, isLoading: false, isSuccess: false,
            })
        }

        props.isUploading(false);
        setRequestCreateUserMedia({
            uploadStatus: uploadStatus.complete, isError: false, isLoading: false, isSuccess: true,
        })
    }

    return (
        <div className="bx--file__container">
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isError={requestCreateUserMedia.isError}
                        isLoading={requestCreateUserMedia.isLoading}
                        isPermanentSuccess={requestCreateUserMedia.isSuccess}
                        errorMessage="We Could Not Upload the File, Please Try Again"
                        successMessage="Successfully Uploaded File"
                    />
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column className={style.parent}>
                    <FileUploader
                        filenameStatus={requestCreateUserMedia.uploadStatus}
                        accept={props.acceptedFormats}
                        buttonKind="secondary"
                        buttonLabel="Select File"
                        iconDescription="Clear File"
                        labelDescription={props.uploadDescription}
                        labelTitle={props.uploadText ? props.uploadText : "Upload"}
                        onChange={fileSelected}
                        disabled={props.isFormDisabled}
                    />
                </Column>
                {(fileUrl && props.isImage) && (
                    <>
                        <Column className={style.parent}>
                            <img className={style.imageContainer} alt="Selected" src={fileUrl}/>
                        </Column>
                    </>
                )}
                {(fileUrl && props.isVideo) && (
                    <Column className={style.parent}>
                        <video controls className={style.videoContainer} src={fileUrl}/>
                    </Column>
                )}
            </Row>
            {(fileUrl && (
                <div>
                    <Row>
                        <Column>
                            <Button
                                className={style.buttonCentre}
                                onClick={fileUpload}
                                disabled={
                                    requestCreateUserMedia.isLoading ||
                                    requestCreateUserMedia.isSuccess ||
                                    props.isFormDisabled ||
                                    !!props.existingFileUrl
                                }
                            >
                                {props.isImage && <span>Save Image</span>}
                                {props.isVideo && <span>Save Video</span>}
                            </Button>
                        </Column>
                    </Row>
                    {(requestCreateUserMedia.uploadStatus === uploadStatus.edit && !props.existingFileUrl) && (
                        <Row>
                            <Column>
                                <Tag className={style.tag} type="red" title="Please Save">
                                    Please Save your Selected Media
                                </Tag>
                            </Column>
                        </Row>
                    )}
                    {requestCreateUserMedia.uploadStatus === uploadStatus.complete && (
                        <Row>
                            <Column>
                                <Tag className={style.tag} type="green" title="Media Saved">Media Saved</Tag>
                            </Column>
                        </Row>
                    )}
                </div>
            ))}
        </div>
    )
};
