module.exports = {
    cognitoUsers: ({ cognitoUserId }) => `https://ap-southeast-2.console.aws.amazon.com/cognito/users/?region=ap-southeast-2#/pool/${process.env.REACT_APP_USER_POOL_ID}/users/${cognitoUserId}`,
    advertisementDetails: ({ advertisementId }) => `/advertisements/details/${advertisementId}`,
    storyDetails: ({ storyId }) => `/stories/details/${storyId}`,
    trailDetails: ({ trailId }) => `/trails/details/${trailId}`,
    createAdvertisement: () => '/advertisements/create',
    listAdvertisement: () => '/advertisements/list',
    createStory: () => '/stories/create',
    createTrail: () => '/trails/create',
    listStory: () => '/stories/list',
    listTrails: () => '/trails/list',
    googleMaps: ({ latitude, longitude }) => `https://google.com/maps/?q=${latitude},${longitude}`,
    mediaUpload: ({ objectId }) => `https://${process.env.REACT_APP_MEDIA_BUCKET}/${objectId}`,
    sharePage: ({ objectId }) => `${process.env.REACT_APP_MEDIA_CDN_BASE_DOMAIN}/pages/${objectId}.html`,
    termsAndConditions: () => 'https://media.yesterdaystories.net.au/docs/terms-and-conditions.pdf',
    termsAndConditionsFilmFestival: () => 'https://media.yesterdaystories.net.au/docs/terms-and-conditions-film-festival.pdf',
};
