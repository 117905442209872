import React, {useState, useEffect} from 'react';
import {
    FormLabel,
    Button,
    TextInput,
    FormGroup,
    Checkbox,
    InlineNotification,
    Link,
    MultiSelect,
    Dropdown,
} from 'carbon-components-react';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {Column, Row} from "./Layout";
import {spacing} from "../constants/styles.constants";
import endpoints from "../constants/endpoints.constants";
import forms from "../constants/form.constants";
import Loading from "./Loading";
import MediaUpload from "./MediaUpload";
import style from "./TrailDetails.module.scss";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: "#e0e0e0",
    border: isDragging ? "solid #393939 1px" : "none",
    ...draggableStyle
});

const getListStyle = () => ({
    background: "#f4f4f4",
    padding: grid,
    width: 250
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// eslint-disable-next-line
export default (props) => {
    const {trailData, createdTrail, storyList} = props;
    const isEdit = Object.keys(trailData).length > 0
    const [existingFileUrlPhotoOne, setExistingFileUrlPhotoOne] = useState('')
    const [existingFileUrlPhotoTwo, setExistingFileUrlPhotoTwo] = useState('')
    const [isMediaPhotoUploadingOne, setIsMediaPhotoUploadingOne] = useState(false);
    const [isMediaPhotoUploadingTwo, setIsMediaPhotoUploadingTwo] = useState(false);
    const [agreedTermsConditions, setAgreedTermsConditions] = useState(false);
    const [formData, setFormData] = useState({
        trailTitle: '',
        trailDescription: '',
        trailDifficulty: 'Easy',
        trailDistance: '',
        storyIdPlacementList: [],
        headerOneKey: '',
        headerTwoKey: '',
        agreedToTermsAndConditions: false,
    });

    useEffect(() => {
        if (isEdit) {
            setFormData({
                trailTitle: trailData.trailTitle,
                trailDescription: trailData.trailDescription,
                trailDifficulty: trailData.trailDifficulty,
                trailDistance: trailData.trailDistance,
                storyIdPlacementList: trailData.storyIdPlacementList,
                headerOneKey: trailData.headerOneKey,
                headerTwoKey: trailData.headerTwoKey,
                agreedToTermsAndConditions: false,
            })
            setExistingFileUrlPhotoOne(endpoints.mediaUpload({objectId: trailData.headerOneKey}))
            setExistingFileUrlPhotoTwo(endpoints.mediaUpload({objectId: trailData.headerTwoKey}))
        }
    }, [trailData, isEdit])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            formData.storyIdPlacementList,
            result.source.index,
            result.destination.index
        );

        setFormData({
            ...formData,
            storyIdPlacementList: items,
        })
    }

    const isFormDisabled = (props.isLoading || props.isSuccess);

    return (
        <>
            <Row space={spacing.medium}>
                <Column>
                    <h3 className={style.bottomPadding}>New Trail</h3>
                    <h4 className={style.bottomPadding}>Trail details</h4>
                    <p>Enter further details about the Trail experience you want to create</p>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isLoading={props.isLoading}
                        isSuccess={props.isSuccess}
                        isError={props.isError}
                        errorMessage={props.errorMessage ? props.errorMessage.split(';') : ''}
                    />
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <TextInput
                            helperText="Set a title for your Trail"
                            id="trailTitle"
                            labelText="Trail Title"
                            placeholder="Trail Title"
                            value={formData.trailTitle}
                            disabled={isFormDisabled}
                            onChange={(event) => setFormData({
                                ...formData,
                                trailTitle: event.target.value
                            })}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <TextInput
                            type={"url"}
                            helperText="What is your trail about?"
                            id="trailDescription"
                            labelText="Trail Description"
                            placeholder="Trail Description"
                            disabled={isFormDisabled}
                            value={formData.trailDescription}
                            onChange={(event) => setFormData({
                                ...formData,
                                trailDescription: event.target.value
                            })}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <Dropdown
                            id="difficulty"
                            titleText="Trail Difficulty"
                            helperText="The Difficulty of your Trail"
                            label="Trail Difficulty"
                            items={forms.difficultyTypes}
                            itemToString={(item) => (item ? item.text : '')}
                            disabled={isFormDisabled || props.storyLockParam}
                            selectedItem={(() => {
                                for (let i = 0; i < forms.difficultyTypes.length; i += 1) {
                                    if (forms.difficultyTypes[i].text === formData.trailDifficulty) {
                                        return forms.difficultyTypes[i]
                                    }
                                }

                                return forms.difficultyTypes[0]
                            })()}
                            onChange={(event) => {
                                setFormData({
                                    ...formData,
                                    trailDifficulty: event.selectedItem.text
                                })
                            }}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <TextInput
                            id="distance"
                            helperText="The Distance of your Trail"
                            labelText="Trail Distance"
                            label="Trail Distance"
                            placeholder="5km"
                            value={formData.trailDistance}
                            disabled={isFormDisabled}
                            onChange={(event) => setFormData({
                                ...formData,
                                trailDistance: event.target.value
                            })}
                        />
                    </FormGroup>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormLabel>Story List Order</FormLabel>
                    {formData.storyIdPlacementList.length > 0 ?
                        <div className="bx--form__helper-text">Your trails stories will appear in this order. Click and
                            drag to rearrange order</div> :
                        <div className="bx--form__helper-text">Select stories below and they will show in the order
                            selected here</div>
                    }
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    {formData.storyIdPlacementList.length > 0 ?
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {formData.storyIdPlacementList.map((storyId, key) => (
                                            <Draggable key={`item-${storyId}`} draggableId={`item-${storyId}`}
                                                       index={key}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {(() => {
                                                            const foundStory = storyList.find(story => story.id === parseInt(storyId));
                                                            return (
                                                                <span
                                                                    key={key}>{foundStory ? foundStory.storyName : "Disabled Story"}</span>)
                                                        })()}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext> : <></>}
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <FormGroup legendText={''}>
                        <MultiSelect.Filterable
                            id="story-selection"
                            ariaLabel="Select Stories"
                            items={storyList.map(story => ({id: story.id, text: story.storyName}))}
                            label="Story List"
                            titleText="Story Selection"
                            itemToString={(item) => (item ? item.text : '')}
                            disabled={isFormDisabled || props.storyLockParam}
                            placeholder={"Search for Stories"}
                            helperText="Select the stories you want your advertisement to show on"
                            initialSelectedItems={(() => {
                                const finalStorySelection = [];

                                for (const selectedStoryId of formData.storyIdPlacementList) {
                                    const foundStory = storyList.find(story => story.id === parseInt(selectedStoryId));
                                    if (foundStory) {
                                        finalStorySelection.push({
                                            id: parseInt(selectedStoryId),
                                            text: foundStory.storyName,
                                        })
                                    }
                                }

                                return finalStorySelection
                            })()}
                            onChange={(event) =>
                                setFormData({
                                    ...formData,
                                    storyIdPlacementList: event.selectedItems.map(story => story.id),
                                })
                            }
                        />
                    </FormGroup>
                </Column>
            </Row>
            <hr/>
            <Row space={spacing.medium}>
                <Column>
                    <h4 className={style.bottomPadding}>Header Images</h4>
                    <p>Upload two header images for your trail</p>
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <MediaUpload
                        uploadDescription={"Please select JPG or PNG files 5Mb or Less"}
                        uploadText={"Header Image One"}
                        isVideo={false}
                        isImage={true}
                        existingFileUrl={existingFileUrlPhotoOne}
                        isUploading={(uploadingStatus) => setIsMediaPhotoUploadingOne(uploadingStatus)}
                        acceptedFormats={[
                            '.png',
                            '.jpg',
                            '.jpeg',
                        ]}
                        isFormDisabled={isFormDisabled}
                        selectFileChange={(event) => {
                            setFormData({
                                ...formData,
                                headerOneKey: '',
                            })
                            setExistingFileUrlPhotoOne(event)
                        }}
                        onChangeWholeData={(event) => setFormData({
                            ...formData,
                            headerOneKey: event.fileKey,
                        })}
                    />
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <MediaUpload
                        uploadDescription={"Please select JPG or PNG files 5Mb or Less"}
                        uploadText={"Header Image Two"}
                        isVideo={false}
                        isImage={true}
                        existingFileUrl={existingFileUrlPhotoTwo}
                        isUploading={(uploadingStatus) => setIsMediaPhotoUploadingTwo(uploadingStatus)}
                        acceptedFormats={[
                            '.png',
                            '.jpg',
                            '.jpeg',
                        ]}
                        isFormDisabled={isFormDisabled}
                        selectFileChange={(event) => {
                            setFormData({
                                ...formData,
                                headerTwoKey: '',
                            })
                            setExistingFileUrlPhotoTwo(event)
                        }}
                        onChangeWholeData={(event) => setFormData({
                            ...formData,
                            headerTwoKey: event.fileKey,
                        })}
                    />
                </Column>
            </Row>
            <Row space={spacing.medium}>
                <Column>
                    <Checkbox
                        defaultChecked={false}
                        labelText={
                            <span>I Agree to the <a target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={endpoints.termsAndConditions()}>
                                        Terms and Conditions
                                    </a>
                                </span>
                        }
                        id="terms-conditions"
                        value={agreedTermsConditions}
                        disabled={isFormDisabled}
                        onChange={(event) => setAgreedTermsConditions(event)}
                    />
                </Column>
            </Row>
            <Row className={style.termsAndConditions} space={spacing.small}>
                <Column>
                    <FormLabel>
                        Yesterday Stories Pty Ltd reserves the right not to publish this trail or content including
                        but not limited to low sound and video quality, disputable history, stories which cause
                        offence
                        or not aligned with community standards and expectation. Please see the &nbsp;
                        <a rel="noopener noreferrer" target="_blank"
                           href='https://media.yesterdaystories.net.au/docs/terms-and-conditions.pdf'>
                            Terms and Conditions
                        </a>
                        &nbsp;for more details.
                    </FormLabel>
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isLoading={props.isLoading}
                        isSuccess={props.isSuccess}
                        isError={props.isError}
                        errorMessage={props.errorMessage ? props.errorMessage.split(';') : ''}
                    />
                </Column>
            </Row>
            <Row space={spacing.small}>
                <Column>
                    {(createdTrail && !isEdit) && (
                        <>
                            <InlineNotification
                                kind={'success'}
                                iconDescription="Trail Created"
                                subtitle={(
                                    <>
                                        <Link href="/trails/list">Go back to the Trail list here</Link>
                                    </>
                                )}
                                title="Trail Created"
                                hideCloseButton={true}
                            />
                        </>
                    )}
                </Column>
            </Row>
            <Row space={spacing.large}>
                <Column>
                    <Button
                        kind="primary"
                        tabIndex={0}
                        disabled={props.isLoading || props.isSuccess || isMediaPhotoUploadingOne || isMediaPhotoUploadingTwo}
                        onClick={() => {
                            props.submit(formData, isEdit, agreedTermsConditions)
                        }}
                    >
                        Save Trail
                    </Button>
                </Column>
            </Row>
        </>
    )
}
