import { InlineNotification } from "carbon-components-react";
import { useSpring, animated } from 'react-spring'
import React from "react";

// eslint-disable-next-line
export default (props) => {
    const loadingAnimation = useSpring({ from: { opacity: 1 }, to: { opacity: 0 }, delay: 3000 });
    const removeAnimation = useSpring({ from: { display: "inline" }, to: { display: "none" }, delay: 3600 });

    return (
        <>
            {props.isLoading && <InlineNotification
                kind={'info'}
                iconDescription="Processing Request"
                subtitle={<span>Processing Request</span>}
                title="Loading"
                hideCloseButton={true}
            />

            }
            {props.isError && <InlineNotification
                kind={'error'}
                iconDescription="Request Error"
                subtitle={<span>{Array.isArray(props.errorMessage) ? props.errorMessage.map((error, id) => <div key={id}>{error}<br></br></div>) : props.errorMessage.toString()}</span>}
                title="Error"
                hideCloseButton={true}
            />}
            {props.isSuccess && <animated.div style={loadingAnimation}>
                <animated.div style={removeAnimation}>
                    <InlineNotification
                        kind={'success'}
                        iconDescription="Request Success"
                        subtitle={<span>{props.successMessage}</span>}
                        title="Success"
                        hideCloseButton={true}
                    />
                </animated.div>
            </animated.div>}
            {props.isPermanentSuccess && <InlineNotification
                kind={'success'}
                iconDescription="Request Success"
                subtitle={<span>{props.successMessage}</span>}
                title="Success"
                hideCloseButton={true}
            />}
            {props.isSuccess && props.children}
        </>
    )
}
