import React, { useState, useEffect } from 'react';
import {
    Button,
    TextInput,
    FormGroup,
    Dropdown,
    Checkbox,
    MultiSelect,
    InlineNotification,
    Link,
    UnorderedList,
} from 'carbon-components-react';
import { Column, Row } from "./Layout";
import { spacing } from "../constants/styles.constants";
import { adTypes, defaultCompanyLocation, storyCategories } from "../constants/form.constants";
import endpoints from "../constants/endpoints.constants";
import style from "./AdvertisementDetails.module.scss"
import Loading from "./Loading";
import MapMarker from "./MapMarker";
import MediaUpload from "./MediaUpload";
import { useGlobalState } from "../hooks/useGlobalState";
import PurchaseDetails from "./PurchaseDetails";
import PurchaseSummary from "./PurchaseSummary";
import PurchaseCoupons from "./PurchaseCoupons";

// eslint-disable-next-line
export default (props) => {
    const { adData, userData, createdAd, storyList } = props;
    const isEdit = Object.keys(adData).length > 0
    const [existingFileUrl, setExistingFileUrl] = useState('')
    const [isMediaUploading, setIsMediaUploading] = useState(false);
    const [hasDeterminedEdit, setHasDeterminedEdit] = useState(false);
    const [hasPaid, setHasPaid] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [categoryIsDisabled, setCategoryIsDisabled] = useState(adData.storyCategories ?
        (adData.storyCategories.length === 0) : true
    );
    const [, { setAdvertisementPinLocation }] = useGlobalState();
    const [formData, setFormData] = useState({
        advertisementName: '',
        companyName: '',
        advertisementOneLiner: '',
        advertisementLink: '',
        companyLatitude: defaultCompanyLocation.latitude,
        companyLongitude: defaultCompanyLocation.longitude,
        advertisementTypeList: [1],
        advertisementMediaKey: 0,
        storyCategories: [],
        storyIdPlacementList: (() => {
            if (props.storyLockParam) {
                return [props.storyLockParam]
            }

            if (props.storyIdParam) {
                return [props.storyIdParam]
            }

            return []
        })(),
        agreedToTermsAndConditions: false,
        freeAd: false,
        couponCode: '',
    });

    useEffect(() => {
        if (isEdit) {
            setFormData({
                advertisementName: adData.advertisementName,
                companyName: adData.companyName,
                advertisementOneLiner: adData.advertisementOneLiner,
                advertisementLink: adData.advertisementLink,
                companyLatitude: adData.companyLatitude,
                companyLongitude: adData.companyLongitude,
                advertisementTypeList: adData.advertisementTypeList,
                advertisementMediaKey: adData.Medium.id,
                storyCategories: adData.storyCategories,
                storyIdPlacementList: adData.storyIdPlacementList,
                agreedToTermsAndConditions: false,
                couponCode: '',
            })
            setAdvertisementPinLocation({
                location: {
                    lat: adData.companyLatitude,
                    lng: adData.companyLongitude,
                }
            })
            setExistingFileUrl(endpoints.mediaUpload({ objectId: adData.Medium.objectKey }))
            setHasPaid(adData.isPaid)
        }

        setHasDeterminedEdit(true)
    }, [setAdvertisementPinLocation, props.adData, adData, isEdit])

    const isFormDisabled = (props.isLoading || props.isSuccess);

    const onCouponApply = ({ discountAmount }) => {
        setDiscountAmount(discountAmount)
    }

    return (
        <>
            {hasDeterminedEdit && <>
                <Row space={spacing.medium}>
                    <Column>
                        <h3 className={style.bottomPadding}>New Advertisement</h3>
                        <p>Enter further details about the Advertisement you want to show.</p>
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        <Loading
                            isLoading={props.isLoading}
                            isSuccess={props.isSuccess}
                            isError={props.isError}
                            errorMessage={props.errorMessage}
                        />
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        <FormGroup legendText={''}>
                            <TextInput
                                helperText="Set a name for the Advertisement for your reference"
                                id="adName"
                                labelText="Advertisement Name"
                                placeholder="Advertisement Name"
                                value={formData.advertisementName}
                                disabled={isFormDisabled}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    advertisementName: event.target.value
                                })}
                            />
                        </FormGroup>
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        <FormGroup legendText={''}>
                            <TextInput
                                helperText="The name of the business advertising"
                                id="companyName"
                                labelText="Business Name"
                                placeholder="Business Name"
                                value={formData.companyName}
                                disabled={isFormDisabled}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    companyName: event.target.value
                                })}
                            />
                        </FormGroup>
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        <FormGroup legendText={''}>
                            <TextInput
                                helperText="Try to keep this short and sweet"
                                id="oneLiner"
                                labelText="Advertisement One Liner"
                                placeholder="Advertisement One Liner"
                                value={formData.advertisementOneLiner}
                                disabled={isFormDisabled}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    advertisementOneLiner: event.target.value
                                })}
                            />
                        </FormGroup>
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        <FormGroup legendText={''}>
                            <TextInput
                                type={"url"}
                                helperText="Link shown to users when they click on the Advertisement"
                                id="adLinks"
                                labelText="Link to Your Website"
                                placeholder="Advertisement Link"
                                value={formData.advertisementLink}
                                disabled={isFormDisabled}
                                onChange={(event) => setFormData({
                                    ...formData,
                                    advertisementLink: event.target.value
                                })}
                            />
                        </FormGroup>
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column className={style.smallBottom}>
                        <FormGroup legendText={''}>
                            <MultiSelect.Filterable
                                id="story-selection"
                                ariaLabel="Story Selection"
                                items={storyList.map(story => ({ id: story.id, text: story.storyName }))}
                                label="Story List"
                                titleText="Story Selection"
                                itemToString={(item) => (item ? item.text : '')}
                                disabled={isFormDisabled || props.storyLockParam}
                                placeholder={"Search for Stories"}
                                helperText="Select the stories you want your advertisement to show on"
                                initialSelectedItems={(() => {
                                    const finalStorySelection = [];

                                    for (const selectedStoryId of formData.storyIdPlacementList) {
                                        const foundStory = storyList.find(story => story.id === parseInt(selectedStoryId));
                                        if (foundStory) {
                                            finalStorySelection.push({
                                                id: parseInt(selectedStoryId),
                                                text: foundStory.storyName,
                                            })
                                        }
                                    }

                                    return finalStorySelection
                                })()}
                                onChange={(event) =>
                                    setFormData({
                                        ...formData,
                                        storyIdPlacementList: event.selectedItems.map(story => story.id),
                                    })
                                }
                            />
                        </FormGroup>
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        <FormGroup legendText={''}>
                            {formData.storyIdPlacementList.length > 0 && (
                                <div className={"bx--form__helper-text"}>Your Advertisement will be placed on the following stories:</div>
                            )}
                            <UnorderedList>
                                {formData.storyIdPlacementList.map((storyId, key) => {
                                    const foundStory = storyList.find(story => story.id === parseInt(storyId));
                                    return (<li className={style.listPadding} key={key}>- {foundStory ? foundStory.storyName : "Disabled Story"}</li>)
                                })}
                            </UnorderedList>
                        </FormGroup>
                    </Column>
                </Row>
                <Row space={spacing.medium}>
                    <Column>
                        <label className={'bx--label'}>Choose Ad Location</label>
                        <div className={'bx--form__helper-text'}>
                            Your Ad will be shown on Stories around this selected location. You may choose your business
                            location.
                        </div>
                        <MapMarker
                            searchText="Search Your Business Location"
                            isFormDisabled={isFormDisabled}
                            isAdvertisement={true}
                            onChange={(event) => setFormData({
                                ...formData,
                                companyLatitude: event.lat,
                                companyLongitude: event.lng,
                            })}
                        />
                    </Column>
                </Row>
                <hr/>
                <Row space={spacing.medium}>
                    <Column>
                        <p>Select what type of Advertisement and Advertisement placement</p>
                    </Column>
                </Row>
                {formData.advertisementTypeList.map((advertisementType, key) => {
                    const isOnlyElement = formData.advertisementTypeList.length === 1;
                    const isFirstElement = key === 0;
                    const selectedItem = adTypes.find(adType => adType.databaseId === parseInt(advertisementType));

                    return (
                        <>
                            <Row key={`${key}-add`} space={spacing.medium}>
                                <Column>
                                    <Dropdown
                                        id="price-selector"
                                        ariaLabel="Price Selector"
                                        items={adTypes.reverse().map(ad => ({ ...ad, indexPosition: key }))}
                                        itemToString={item => (item ? item.text : '')}
                                        label="Select Ad type"
                                        titleText="Select Your Ad Type and Story Placement Count"
                                        initialSelectedItem={selectedItem}
                                        selectedItem={selectedItem}
                                        disabled={isFormDisabled || isEdit}
                                        className={style.dropDownMaxWidth}
                                        onChange={(event) => {
                                            const { databaseId, indexPosition } = event.selectedItem;
                                            const newAdList = [...formData.advertisementTypeList];
                                            newAdList[indexPosition] = databaseId;
                                            setFormData({
                                                ...formData,
                                                advertisementTypeList: newAdList,
                                            })
                                        }}
                                    />
                                </Column>
                            </Row>
                            <Row key={`${key}-delete`} space={spacing.medium}>
                                {!isEdit && <Column>
                                    {(!isOnlyElement && !isFirstElement) && <Button
                                        className={style.deleteButtonPadding}
                                        kind='danger'
                                        size='field'
                                        index={key}
                                        onClick={(event) => {
                                            const buttonPosition = parseInt(event.target.getAttribute("index"));
                                            formData.advertisementTypeList.splice(buttonPosition, 1)
                                            setFormData({
                                                ...formData,
                                            })
                                        }}
                                    >
                                        Delete Product
                                    </Button>}
                                </Column>}
                            </Row>
                        </>
                    )
                })}

                {!isEdit && <Row space={spacing.medium}>
                    <Column className={style.addMoreWidthMax}>
                        <Button
                            className={style.deleteButtonPadding}
                            kind='secondary'
                            size='field'
                            tabIndex={0}
                            onClick={() => {
                                formData.advertisementTypeList.push(1)
                                setFormData({
                                    ...formData,
                                    advertisementTypeList: [...formData.advertisementTypeList]
                                })
                            }}
                        >
                            Add More
                        </Button>
                    </Column>
                </Row>}

                {!isEdit && (
                    <>
                        <Row space={spacing.medium}>
                            <Column>
                                <PurchaseCoupons
                                    promoCode={props.promoCode}
                                    onApply={onCouponApply}
                                    setCouponCode={({ code }) => setFormData({
                                        ...formData,
                                        couponCode: code,
                                    })}
                                />
                            </Column>
                        </Row>
                        <Row space={spacing.medium}>
                            <Column>
                                <PurchaseSummary
                                    discountAmount={discountAmount}
                                    advertisementTypeList={formData.advertisementTypeList}
                                />
                            </Column>
                        </Row>
                    </>
                )}

                <hr/>
                <Row space={spacing.medium}>
                    <Column>
                        <p>Category Selection</p>
                    </Column>
                </Row>
                <Row space={spacing.medium}>
                    <Column>
                        <Checkbox
                            defaultChecked={adData.storyCategories ? (adData.storyCategories.length === 0) : true}
                            labelText="Place Advertisement on Any Story Regardless of Category"
                            id="story-category-disabled"
                            value={categoryIsDisabled}
                            disabled={isFormDisabled}
                            onChange={(event) => {
                                setCategoryIsDisabled(event)
                                setFormData({
                                    ...formData,
                                    storyCategories: [],
                                })
                                document.querySelectorAll(".categorySelector .bx--list-box__selection--multi")
                                    .forEach(e => e.click());
                            }}
                        />
                    </Column>
                </Row>
                <Row space={spacing.medium}>
                    <Column>
                        <MultiSelect
                            class="categorySelector"
                            id="category-selector"
                            items={storyCategories}
                            itemToString={(item) => (item ? item.text : '')}
                            initialSelectedItems={(() => {
                                if (!isEdit) {
                                    return []
                                }

                                const finalList = []

                                for (const categoryId of adData.storyCategories) {
                                    const storyCategory = storyCategories.find(dbCategory => dbCategory.databaseId === parseInt(categoryId));
                                    if (storyCategory) {
                                        finalList.push(storyCategory)
                                    }
                                }

                                return finalList;
                            })()}
                            ariaLabel="Category Selector"
                            label="Select Categories"
                            titleText="Select Your Categories You Want Your Ads to Show on"
                            disabled={isFormDisabled || categoryIsDisabled}
                            onChange={(event) =>
                                setFormData({
                                    ...formData,
                                    storyCategories: event.selectedItems.map(story => story.databaseId),
                                })
                            }
                        />
                    </Column>
                </Row>
                <hr/>
                <Row space={spacing.medium}>
                    <Column>
                        <p>Upload An Image Or A Logo For Your Ad</p>
                    </Column>
                </Row>
                <Row space={spacing.medium}>
                    <Column>
                        <MediaUpload
                            uploadDescription={"Please select JPEG or PNG file 5Mb or less that is a square size. Recommended size is 500px by 500px."}
                            isVideo={false}
                            isImage={true}
                            existingFileUrl={existingFileUrl}
                            acceptedFormats={[
                                '.jpg',
                                '.jpeg',
                                '.png',
                            ]}
                            isFormDisabled={isFormDisabled}
                            isUploading={(uploadingStatus) => setIsMediaUploading(uploadingStatus)}
                            selectFileChange={(event) => {
                                setFormData({
                                    ...formData,
                                    advertisementMediaKey: 0,
                                })
                                setExistingFileUrl(event)
                            }}
                            onChange={(event) => setFormData({
                                ...formData,
                                advertisementMediaKey: event,
                            })}
                        />
                    </Column>
                </Row>
                <hr/>
                {(userData.stripePaymentId === '0') && (
                    <>
                        <Row space={spacing.medium}>
                            <Column>
                                <p>Save and Payment</p>
                            </Column>
                        </Row>
                        <Row space={spacing.medium}>
                            <Column>
                                <PurchaseDetails/>
                            </Column>
                        </Row>
                    </>
                )}
                {!isEdit && (
                    <>
                        <Row space={spacing.medium}>
                            <Column>
                                <PurchaseSummary
                                    discountAmount={discountAmount}
                                    advertisementTypeList={formData.advertisementTypeList}
                                />
                            </Column>
                        </Row>
                    </>
                )}
                <Row space={spacing.medium}>
                    <Column>
                        <Checkbox
                            defaultChecked={false}
                            labelText={
                                <span>I Agree to the <a target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={endpoints.termsAndConditions()}>
                                        Terms and Conditions
                                    </a>
                                </span>
                            }
                            id="terms-conditions"
                            value={formData.agreedToTermsAndConditions}
                            disabled={isFormDisabled}
                            onChange={(event) => setFormData({
                                ...formData,
                                agreedToTermsAndConditions: event,
                            })}
                        />
                    </Column>
                </Row>
                {userData.isAdmin && <Row space={spacing.medium}>
                    <Column>
                        <Checkbox
                            defaultChecked={false}
                            labelText="This is a Free Ad - Select This Box to ByPass Payment"
                            id="no-charge"
                            value={formData.freeAd}
                            disabled={isFormDisabled}
                            onChange={(event) => setFormData({
                                ...formData,
                                freeAd: event,
                            })}
                        />
                    </Column>
                </Row>}
                <Row space={spacing.small}>
                    <Column>
                        <Loading
                            isLoading={props.isLoading}
                            isSuccess={props.isSuccess}
                            isError={props.isError}
                            errorMessage={props.errorMessage}
                        />
                    </Column>
                </Row>
                <Row space={spacing.small}>
                    <Column>
                        {createdAd && (
                            <>
                                <InlineNotification
                                    kind={'success'}
                                    iconDescription="Story Purchased"
                                    subtitle={(
                                        <>
                                            {createdAd.data.advertisementData.stripeSubscriptionId &&
                                            <span>Subscription ID is {createdAd.data.advertisementData.stripeSubscriptionId}. You will shortly receive a receipt via email.</span>}
                                            <p>This Ad was places on the following stories:</p>
                                            {createdAd.data.storyList.largeStoryList.concat(createdAd.data.storyList.miniStoryList).map(storyId => {
                                                const foundStory = createdAd.data.storyData.filter(story => story.id === storyId)
                                                if (foundStory[0]) {
                                                    return <p key={foundStory[0].id}>{foundStory[0].storyName}</p>
                                                } else {
                                                    return <></>
                                                }
                                            })}
                                            <Link href="/advertisements/list">Go back to the Advertisement list
                                                here</Link>
                                        </>
                                    )}
                                    title="Story Purchased"
                                    hideCloseButton={true}
                                />
                            </>
                        )}
                    </Column>
                </Row>
                <Row space={spacing.large}>
                    <Column>
                        {!hasPaid ?
                            <Button
                                kind="primary"
                                tabIndex={0}
                                disabled={props.isLoading || props.isSuccess || isMediaUploading}
                                onClick={() => props.submit(formData, false, isEdit)}
                            >
                                Save and Purchase
                            </Button> : <Button
                                className={!hasPaid ? style.marginLeft : ''}
                                kind="secondary"
                                tabIndex={0}
                                disabled={props.isLoading || props.isSuccess || isMediaUploading}
                                onClick={() => props.submit(formData, true, isEdit)}
                            >
                                Save
                            </Button>
                        }
                    </Column>
                </Row>
            </>}
        </>
    )
}
