import React, { useEffect, useState } from 'react';
import { spacing } from '../../constants/styles.constants';
import { Column, Container, Row } from '../../components/Layout';
import AdvertisementsList from "../../components/AdvertisementsList";
import Loading from "../../components/Loading";
import LogoHeading from "../../components/LogoHeading";
import { useGlobalState } from "../../hooks/useGlobalState";
import { genericRequest } from "../../utils/request";

// eslint-disable-next-line
export default () => {
    const [{ advertisementList }, { setAdvertisementList }] = useGlobalState();
    const [userData, setUserData] = useState({});
    const [requestStatus, setRequestStatus] = useState({
        isError: false, isLoading: false, isSuccess: false
    });
    const [requestUserDataStatus, setRequestUserDataStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });

    useEffect(() => {
        async function getUserData() {
            setRequestUserDataStatus({ isLoading: true });
            const request = await genericRequest({
                endpoint: 'getUserDetails',
                method: 'GET',
            });

            if (request.response) {
                setUserData(request.response.data);
                return setRequestUserDataStatus({ isSuccess: true });
            }

            return setRequestUserDataStatus({ isError: request.error });
        }

        async function getPageData() {
            setRequestStatus({ isLoading: true });
            const request = await genericRequest({ endpoint: 'getAdvertisementListDetails' });

            if (request.response) {
                setAdvertisementList({ data: request.response.data });
                return setRequestStatus({ isSuccess: true });
            }

            return setRequestStatus({ isError: request.error });
        }

        getPageData().then()
        getUserData().then()
    }, [setRequestStatus, setAdvertisementList, setRequestUserDataStatus, setUserData]);

    return (
        <Container>
            <LogoHeading/>
            <Loading isLoading={requestStatus.isLoading || requestUserDataStatus.isLoading}
                     isError={requestStatus.isError || requestUserDataStatus.isError}
                     isSuccess={requestStatus.isSuccess  && requestUserDataStatus.isSuccess}
                     errorMessage={"We Couldn't List Advertisements At This Time"}
                     successMessage={"We got the Advertisements List"}
            >
                <Row space={spacing.large}>
                    <Column space={spacing.large}>
                        <AdvertisementsList
                            advertisementList={advertisementList}
                            userData={userData}
                        />
                    </Column>
                </Row>
            </Loading>
        </Container>
    );
};
