import axios from "axios";
import requestConstants from '../constants/requests.constants';

const DOMAIN = requestConstants.apiBaseDomain;
const DOMAIN_TRAILS = requestConstants.apiBaseDomainTrails;
const SIGN_IN = requestConstants.signInDomain;

const genericRequest = async ({ endpoint, method = 'GET', data = {}, isSecured = true }) => {
    const options = {
        method,
        data,
        headers: {},
        url: `${DOMAIN}${endpoint}`,
    };

    if (isSecured) {
        options.headers["Authorization"] = `Bearer ${localStorage.getItem('auth')}`
    }

    try {
        const response = await axios(options);
        if (response.data.hasOwnProperty('errorMessage')) {
            return { response: false, error: response.data.errorMessage };
        }

        return { response, error: false };
    } catch (error) {
        if (error.request.status === 401) {
            window.location.replace(`${SIGN_IN}?redirect=${window.location.href}`);
        } else {
            return { response: false, error: error };
        }
    }
};

const genericTrailsRequest = async ({ endpoint, method = 'GET', data = {}, isSecured = true }) => {
    const options = {
        method,
        data,
        headers: {},
        url: `${DOMAIN_TRAILS}${endpoint}`,
    };

    if (isSecured) {
        options.headers["Authorization"] = `Bearer ${localStorage.getItem('auth')}`
    }

    try {
        const response = await axios(options);
        if (response.data.hasOwnProperty('errorMessage')) {
            return { response: false, error: response.data.errorMessage };
        }

        return { response, error: false };
    } catch (error) {
        if (error.request.status === 401) {
            window.location.replace(`${SIGN_IN}?redirect=${window.location.href}`);
        } else {
            return { response: false, error: error };
        }
    }
};

const uploadRequest = async ({ endpoint, data, contentType }) => {
    try {
        const options = {
            headers: {
                'Content-Type': contentType
            }
        };

        const response = await axios.put(endpoint, data, options);
        return { response, error: false };
    } catch (error) {
        return { response: false, error: error };
    }
};

export {
    genericRequest,
    genericTrailsRequest,
    uploadRequest,
}
