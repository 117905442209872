import React, { useState } from "react";
import { Button, FormGroup, TextInput } from "carbon-components-react";
import { spacing } from "../constants/styles.constants";
import { Column, Row } from "./Layout";
import Loading from "./Loading";
import { genericRequest } from "../utils/request";

// eslint-disable-next-line
export default (props) => {
    const [couponCode, setCouponCode] = useState(props.promoCode ? props.promoCode : '')
    const [isFormDisabled, setIsFormDisabled] = useState(false)
    const [requestVerifyStatus, setRequestVerifyStatus] = useState({
        isError: false, isLoading: false, isSuccess: false,
    });

    const updateCouponCode = ({ code }) => {
        setCouponCode(code)
    }

    const verifyCoupon = async () => {
        const { onApply } = props;
        setIsFormDisabled(true)
        setRequestVerifyStatus({ isLoading: true })

        const request = await genericRequest({
            endpoint: 'checkCoupon',
            method: "POST",
            data: {
                couponCode: couponCode,
            },
        });

        if (request.error) {
            setRequestVerifyStatus({ isError: true })
            setIsFormDisabled(false)
            onApply({ discountAmount: 0 })
            props.setCouponCode({ code: '' })
            return;
        }

        const response = request.response.data;
        onApply({ discountAmount: response.amountOff / 100 })
        props.setCouponCode({ code: response.promoId })

        setRequestVerifyStatus({ isSuccess: true })
        setIsFormDisabled(false)
    }

    return (
        <div>
            <Row space={spacing.small}>
                <Column>
                    <Loading
                        isError={requestVerifyStatus.isError}
                        isLoading={requestVerifyStatus.isLoading}
                        isPermanentSuccess={requestVerifyStatus.isSuccess}
                        errorMessage="We Could Not Apply Your Coupon, Please Try Again"
                        successMessage="Successfully Applied Coupon"
                    />
                </Column>
            </Row>
            <FormGroup legendText={''}>
                <TextInput
                    helperText="Apply a Yesterday Stories Coupon"
                    id="coupon"
                    labelText="Coupon Code"
                    placeholder="Coupon Code"
                    value={couponCode}
                    disabled={isFormDisabled}
                    onChange={(event) => updateCouponCode({ code: event.target.value })}
                />
            </FormGroup>
            <Button
                type={"submit"}
                kind="primary"
                tabIndex={0}
                disabled={isFormDisabled}
                onClick={() => verifyCoupon()}
                style={{ marginRight: 10 }}
            >
                Apply Coupon
            </Button>
        </div>
    )
};
